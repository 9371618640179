// Styles
import "./Insert.css"

// Misc
import {ReactComponent as IconHB} from "assets/icons/small/heartbeat.svg"
import {
    Heartbeat
  } from "elements"

// Countries (ISO 3166-1 alpha 2 and/orISO  3166-2)
import {ReactComponent as IconAD} from "assets/flags/AD.svg"
import {ReactComponent as IconAE} from "assets/flags/AE.svg"
import {ReactComponent as IconAF} from "assets/flags/AF.svg"
import {ReactComponent as IconAG} from "assets/flags/AG.svg"
import {ReactComponent as IconAI} from "assets/flags/AI.svg"
import {ReactComponent as IconAL} from "assets/flags/AL.svg"
import {ReactComponent as IconAM} from "assets/flags/AM.svg"
import {ReactComponent as IconAO} from "assets/flags/AO.svg"
import {ReactComponent as IconAR} from "assets/flags/AR.svg"
import {ReactComponent as IconAS} from "assets/flags/AS.svg"
import {ReactComponent as IconAT} from "assets/flags/AT.svg"
import {ReactComponent as IconAU} from "assets/flags/AU.svg"
import {ReactComponent as IconAW} from "assets/flags/AW.svg"
import {ReactComponent as IconAX} from "assets/flags/AX.svg"
import {ReactComponent as IconAZ} from "assets/flags/AZ.svg"
import {ReactComponent as IconBA} from "assets/flags/BA.svg"
import {ReactComponent as IconBB} from "assets/flags/BB.svg"
import {ReactComponent as IconBD} from "assets/flags/BD.svg"
import {ReactComponent as IconBE} from "assets/flags/BE.svg"
import {ReactComponent as IconBF} from "assets/flags/BF.svg"
import {ReactComponent as IconBG} from "assets/flags/BG.svg"
import {ReactComponent as IconBH} from "assets/flags/BH.svg"
import {ReactComponent as IconBI} from "assets/flags/BI.svg"
import {ReactComponent as IconBJ} from "assets/flags/BJ.svg"
import {ReactComponent as IconBL} from "assets/flags/BL.svg"
import {ReactComponent as IconBM} from "assets/flags/BM.svg"
import {ReactComponent as IconBN} from "assets/flags/BN.svg"
import {ReactComponent as IconBO} from "assets/flags/BO.svg"
import {ReactComponent as IconBQ} from "assets/flags/BQ.svg"
import {ReactComponent as IconBR} from "assets/flags/BR.svg"
import {ReactComponent as IconBS} from "assets/flags/BS.svg"
import {ReactComponent as IconBT} from "assets/flags/BT.svg"
import {ReactComponent as IconBW} from "assets/flags/BW.svg"
import {ReactComponent as IconBY} from "assets/flags/BY.svg"
import {ReactComponent as IconBZ} from "assets/flags/BZ.svg"
import {ReactComponent as IconCA} from "assets/flags/CA.svg"
import {ReactComponent as IconCC} from "assets/flags/CC.svg"
import {ReactComponent as IconCD} from "assets/flags/CD.svg"
import {ReactComponent as IconCF} from "assets/flags/CF.svg"
import {ReactComponent as IconCG} from "assets/flags/CG.svg"
import {ReactComponent as IconCH} from "assets/flags/CH.svg"
import {ReactComponent as IconCK} from "assets/flags/CK.svg"
import {ReactComponent as IconCL} from "assets/flags/CL.svg"
import {ReactComponent as IconCM} from "assets/flags/CM.svg"
import {ReactComponent as IconCN} from "assets/flags/CN.svg"
import {ReactComponent as IconCO} from "assets/flags/CO.svg"
import {ReactComponent as IconCR} from "assets/flags/CR.svg"
import {ReactComponent as IconCU} from "assets/flags/CU.svg"
import {ReactComponent as IconCW} from "assets/flags/CW.svg"
import {ReactComponent as IconCX} from "assets/flags/CX.svg"
import {ReactComponent as IconCY} from "assets/flags/CY.svg"
import {ReactComponent as IconCZ} from "assets/flags/CZ.svg"
import {ReactComponent as IconDE} from "assets/flags/DE.svg"
import {ReactComponent as IconDJ} from "assets/flags/DJ.svg"
import {ReactComponent as IconDK} from "assets/flags/DK.svg"
import {ReactComponent as IconDM} from "assets/flags/DM.svg"
import {ReactComponent as IconDO} from "assets/flags/DO.svg"
import {ReactComponent as IconSD} from "assets/flags/SD.svg"
import {ReactComponent as IconDZ} from "assets/flags/DZ.svg"
import {ReactComponent as IconEC} from "assets/flags/EC.svg"
import {ReactComponent as IconEE} from "assets/flags/EE.svg"
import {ReactComponent as IconEG} from "assets/flags/EG.svg"
import {ReactComponent as IconEH} from "assets/flags/EH.svg"
import {ReactComponent as IconER} from "assets/flags/ER.svg"
import {ReactComponent as IconES} from "assets/flags/ES.svg"
import {ReactComponent as IconET} from "assets/flags/ET.svg"
import {ReactComponent as IconFI} from "assets/flags/FI.svg"
import {ReactComponent as IconFJ} from "assets/flags/FJ.svg"
import {ReactComponent as IconFK} from "assets/flags/FK.svg"
import {ReactComponent as IconFM} from "assets/flags/FM.svg"
import {ReactComponent as IconFO} from "assets/flags/FO.svg"
import {ReactComponent as IconFR} from "assets/flags/FR.svg"
import {ReactComponent as IconGA} from "assets/flags/GA.svg"
import {ReactComponent as IconGB} from "assets/flags/GB.svg"
import {ReactComponent as IconGD} from "assets/flags/GD.svg"
import {ReactComponent as IconGE} from "assets/flags/GE.svg"
import {ReactComponent as IconGG} from "assets/flags/GG.svg"
import {ReactComponent as IconGH} from "assets/flags/GH.svg"
import {ReactComponent as IconGI} from "assets/flags/GI.svg"
import {ReactComponent as IconGL} from "assets/flags/GL.svg"
import {ReactComponent as IconGM} from "assets/flags/GM.svg"
import {ReactComponent as IconGN} from "assets/flags/GN.svg"
import {ReactComponent as IconGQ} from "assets/flags/GQ.svg"
import {ReactComponent as IconGR} from "assets/flags/GR.svg"
import {ReactComponent as IconGT} from "assets/flags/GT.svg"
import {ReactComponent as IconGU} from "assets/flags/GU.svg"
import {ReactComponent as IconGW} from "assets/flags/GW.svg"
import {ReactComponent as IconGY} from "assets/flags/GY.svg"
import {ReactComponent as IconHK} from "assets/flags/HK.svg"
import {ReactComponent as IconHN} from "assets/flags/HN.svg"
import {ReactComponent as IconHR} from "assets/flags/HR.svg"
import {ReactComponent as IconHT} from "assets/flags/HT.svg"
import {ReactComponent as IconHU} from "assets/flags/HU.svg"
import {ReactComponent as IconID} from "assets/flags/ID.svg"
import {ReactComponent as IconIE} from "assets/flags/IE.svg"
import {ReactComponent as IconIL} from "assets/flags/IL.svg"
import {ReactComponent as IconIM} from "assets/flags/IM.svg"
import {ReactComponent as IconIN} from "assets/flags/IN.svg"
import {ReactComponent as IconIO} from "assets/flags/IO.svg"
import {ReactComponent as IconIQ} from "assets/flags/IQ.svg"
import {ReactComponent as IconIR} from "assets/flags/IR.svg"
import {ReactComponent as IconIS} from "assets/flags/IS.svg"
import {ReactComponent as IconIT} from "assets/flags/IT.svg"
import {ReactComponent as IconJE} from "assets/flags/JE.svg"
import {ReactComponent as IconJM} from "assets/flags/JM.svg"
import {ReactComponent as IconJO} from "assets/flags/JO.svg"
import {ReactComponent as IconJP} from "assets/flags/JP.svg"
import {ReactComponent as IconKE} from "assets/flags/KE.svg"
import {ReactComponent as IconKG} from "assets/flags/KG.svg"
import {ReactComponent as IconKH} from "assets/flags/KH.svg"
import {ReactComponent as IconKI} from "assets/flags/KI.svg"
import {ReactComponent as IconKM} from "assets/flags/KM.svg"
import {ReactComponent as IconKN} from "assets/flags/KN.svg"
import {ReactComponent as IconKP} from "assets/flags/KP.svg"
import {ReactComponent as IconKR} from "assets/flags/KR.svg"
import {ReactComponent as IconKW} from "assets/flags/KW.svg"
import {ReactComponent as IconKY} from "assets/flags/KY.svg"
import {ReactComponent as IconKZ} from "assets/flags/KZ.svg"
import {ReactComponent as IconLA} from "assets/flags/LA.svg"
import {ReactComponent as IconLB} from "assets/flags/LB.svg"
import {ReactComponent as IconLC} from "assets/flags/LC.svg"
import {ReactComponent as IconLI} from "assets/flags/LI.svg"
import {ReactComponent as IconLK} from "assets/flags/LK.svg"
import {ReactComponent as IconLR} from "assets/flags/LR.svg"
import {ReactComponent as IconLS} from "assets/flags/LS.svg"
import {ReactComponent as IconLT} from "assets/flags/LT.svg"
import {ReactComponent as IconLU} from "assets/flags/LU.svg"
import {ReactComponent as IconLV} from "assets/flags/LV.svg"
import {ReactComponent as IconLY} from "assets/flags/LY.svg"
import {ReactComponent as IconMA} from "assets/flags/MA.svg"
import {ReactComponent as IconMC} from "assets/flags/MC.svg"
import {ReactComponent as IconMD} from "assets/flags/MD.svg"
import {ReactComponent as IconME} from "assets/flags/ME.svg"
import {ReactComponent as IconMG} from "assets/flags/MG.svg"
import {ReactComponent as IconMH} from "assets/flags/MH.svg"
import {ReactComponent as IconMK} from "assets/flags/MK.svg"
import {ReactComponent as IconML} from "assets/flags/ML.svg"
import {ReactComponent as IconMM} from "assets/flags/MM.svg"
import {ReactComponent as IconMN} from "assets/flags/MN.svg"
import {ReactComponent as IconMO} from "assets/flags/MO.svg"
import {ReactComponent as IconMP} from "assets/flags/MP.svg"
import {ReactComponent as IconMQ} from "assets/flags/MQ.svg"
import {ReactComponent as IconMR} from "assets/flags/MR.svg"
import {ReactComponent as IconMS} from "assets/flags/MS.svg"
import {ReactComponent as IconMT} from "assets/flags/MT.svg"
import {ReactComponent as IconMU} from "assets/flags/MU.svg"
import {ReactComponent as IconMV} from "assets/flags/MV.svg"
import {ReactComponent as IconMW} from "assets/flags/MW.svg"
import {ReactComponent as IconMX} from "assets/flags/MX.svg"
import {ReactComponent as IconMY} from "assets/flags/MY.svg"
import {ReactComponent as IconMZ} from "assets/flags/MZ.svg"
import {ReactComponent as IconNA} from "assets/flags/NA.svg"
import {ReactComponent as IconNE} from "assets/flags/NE.svg"
import {ReactComponent as IconNF} from "assets/flags/NF.svg"
import {ReactComponent as IconNG} from "assets/flags/NG.svg"
import {ReactComponent as IconNI} from "assets/flags/NI.svg"
import {ReactComponent as IconNL} from "assets/flags/NL.svg"
import {ReactComponent as IconNO} from "assets/flags/NO.svg"
import {ReactComponent as IconNP} from "assets/flags/NP.svg"
import {ReactComponent as IconNR} from "assets/flags/NR.svg"
import {ReactComponent as IconNU} from "assets/flags/NU.svg"
import {ReactComponent as IconNZ} from "assets/flags/NZ.svg"
import {ReactComponent as IconOM} from "assets/flags/OM.svg"
import {ReactComponent as IconPA} from "assets/flags/PA.svg"
import {ReactComponent as IconPE} from "assets/flags/PE.svg"
import {ReactComponent as IconPF} from "assets/flags/PF.svg"
import {ReactComponent as IconPG} from "assets/flags/PG.svg"
import {ReactComponent as IconPH} from "assets/flags/PH.svg"
import {ReactComponent as IconPK} from "assets/flags/PK.svg"
import {ReactComponent as IconPL} from "assets/flags/PL.svg"
import {ReactComponent as IconPN} from "assets/flags/PN.svg"
import {ReactComponent as IconPR} from "assets/flags/PR.svg"
import {ReactComponent as IconPS} from "assets/flags/PS.svg"
import {ReactComponent as IconPT} from "assets/flags/PT.svg"
import {ReactComponent as IconPW} from "assets/flags/PW.svg"
import {ReactComponent as IconPY} from "assets/flags/PY.svg"
import {ReactComponent as IconQA} from "assets/flags/QA.svg"
import {ReactComponent as IconRO} from "assets/flags/RO.svg"
import {ReactComponent as IconRS} from "assets/flags/RS.svg"
import {ReactComponent as IconRU} from "assets/flags/RU.svg"
import {ReactComponent as IconRW} from "assets/flags/RW.svg"
import {ReactComponent as IconSA} from "assets/flags/SA.svg"
import {ReactComponent as IconSB} from "assets/flags/SB.svg"
import {ReactComponent as IconSC} from "assets/flags/SC.svg"
import {ReactComponent as IconSE} from "assets/flags/SE.svg"
import {ReactComponent as IconSG} from "assets/flags/SG.svg"
import {ReactComponent as IconSI} from "assets/flags/SI.svg"
import {ReactComponent as IconSK} from "assets/flags/SK.svg"
import {ReactComponent as IconSL} from "assets/flags/SL.svg"
import {ReactComponent as IconSM} from "assets/flags/SM.svg"
import {ReactComponent as IconSN} from "assets/flags/SN.svg"
import {ReactComponent as IconSO} from "assets/flags/SO.svg"
import {ReactComponent as IconSR} from "assets/flags/SR.svg"
import {ReactComponent as IconSS} from "assets/flags/SS.svg"
import {ReactComponent as IconST} from "assets/flags/ST.svg"
import {ReactComponent as IconSV} from "assets/flags/SV.svg"
import {ReactComponent as IconSX} from "assets/flags/SX.svg"
import {ReactComponent as IconSY} from "assets/flags/SY.svg"
import {ReactComponent as IconSZ} from "assets/flags/SZ.svg"
import {ReactComponent as IconTC} from "assets/flags/TC.svg"
import {ReactComponent as IconTD} from "assets/flags/TD.svg"
import {ReactComponent as IconTG} from "assets/flags/TG.svg"
import {ReactComponent as IconTH} from "assets/flags/TH.svg"
import {ReactComponent as IconTJ} from "assets/flags/TJ.svg"
import {ReactComponent as IconTK} from "assets/flags/TK.svg"
import {ReactComponent as IconTL} from "assets/flags/TL.svg"
import {ReactComponent as IconTM} from "assets/flags/TM.svg"
import {ReactComponent as IconTN} from "assets/flags/TN.svg"
import {ReactComponent as IconTO} from "assets/flags/TO.svg"
import {ReactComponent as IconTR} from "assets/flags/TR.svg"
import {ReactComponent as IconTT} from "assets/flags/TT.svg"
import {ReactComponent as IconTV} from "assets/flags/TV.svg"
import {ReactComponent as IconTW} from "assets/flags/TW.svg"
import {ReactComponent as IconTZ} from "assets/flags/TZ.svg"
import {ReactComponent as IconUA} from "assets/flags/UA.svg"
import {ReactComponent as IconUG} from "assets/flags/UG.svg"
import {ReactComponent as IconUS} from "assets/flags/US.svg"
import {ReactComponent as IconUY} from "assets/flags/UY.svg"
import {ReactComponent as IconUZ} from "assets/flags/UZ.svg"
import {ReactComponent as IconVC} from "assets/flags/VC.svg"
import {ReactComponent as IconVE} from "assets/flags/VE.svg"
import {ReactComponent as IconVG} from "assets/flags/VG.svg"
import {ReactComponent as IconVI} from "assets/flags/VI.svg"
import {ReactComponent as IconVN} from "assets/flags/VN.svg"
import {ReactComponent as IconVU} from "assets/flags/VU.svg"
import {ReactComponent as IconWS} from "assets/flags/WS.svg"
import {ReactComponent as IconYE} from "assets/flags/YE.svg"
import {ReactComponent as IconZA} from "assets/flags/ZA.svg"
import {ReactComponent as IconZM} from "assets/flags/ZM.svg"
import {ReactComponent as IconZW} from "assets/flags/ZW.svg"

// Custom
import {ReactComponent as IconUN} from "assets/flags/UN.svg"
import {ReactComponent as IconUndefined} from "assets/flags/undefined.svg"

// Personas
import PicPersonaF15c from "assets/portraits/f15c.png"
import PicPersonaF20c from "assets/portraits/f20c.png"
import PicPersonaF25c from "assets/portraits/f25c.png"
import PicPersonaF30c from "assets/portraits/f30c.png"
import PicPersonaF35c from "assets/portraits/f35c.png"
import PicPersonaF40c from "assets/portraits/f40c.png"
import PicPersonaF45c from "assets/portraits/f45c.png"
import PicPersonaF50c from "assets/portraits/f50c.png"
import PicPersonaF55c from "assets/portraits/f55c.png"
import PicPersonaF60c from "assets/portraits/f60c.png"
import PicPersonaF65c from "assets/portraits/f65c.png"
import PicPersonaF70c from "assets/portraits/f70c.png"
import PicPersonaF75c from "assets/portraits/f75c.png"
import PicPersonaF80c from "assets/portraits/f80c.png"
import PicPersonaM15c from "assets/portraits/m15c.png"
import PicPersonaM20c from "assets/portraits/m20c.png"
import PicPersonaM25c from "assets/portraits/m25c.png"
import PicPersonaM30c from "assets/portraits/m30c.png"
import PicPersonaM35c from "assets/portraits/m35c.png"
import PicPersonaM40c from "assets/portraits/m40c.png"
import PicPersonaM45c from "assets/portraits/m45c.png"
import PicPersonaM50c from "assets/portraits/m50c.png"
import PicPersonaM55c from "assets/portraits/m55c.png"
import PicPersonaM60c from "assets/portraits/m60c.png"
import PicPersonaM65c from "assets/portraits/m65c.png"
import PicPersonaM70c from "assets/portraits/m70c.png"
import PicPersonaM75c from "assets/portraits/m75c.png"
import PicPersonaM80c from "assets/portraits/m80c.png"

// Devices
import {ReactComponent as IconAndroid} from "assets/icons/big/device/androidphone.svg"
import {ReactComponent as IconIPhone} from "assets/icons/big/device/iphone.svg"
import {ReactComponent as IconPC} from "assets/icons/big/device/windowspc.svg"
import {ReactComponent as IconMac} from "assets/icons/big/device/imac.svg"
import {ReactComponent as IconUnknown} from "assets/icons/big/other/unknown.svg"

// Times
import {ReactComponent as IconAm} from "assets/icons/big/other/am.svg"
import {ReactComponent as IconPm} from "assets/icons/big/other/pm.svg"

const country = {
    "AD": [<IconAD/>, "Andorra"],
    "AE": [<IconAE/>, "Emirates"/* United Arab Emirates*/],
    "AF": [<IconAF/>, "Afghanistan"],
    "AG": [<IconAG/>, "Antigua"/* and Barbuda*/],
    "AI": [<IconAI/>, "Anguilla"],
    "AL": [<IconAL/>, "Albania"],
    "AM": [<IconAM/>, "Armenia"],
    "AO": [<IconAO/>, "Angola"],
    "AR": [<IconAR/>, "Argentina"],
    "AS": [<IconAS/>, "American Samoa"],
    "AT": [<IconAT/>, "Austria"],
    "AU": [<IconAU/>, "Australia"],
    "AW": [<IconAW/>, "Aruba"],
    "AX": [<IconAX/>, "Åland Islands"],
    "AZ": [<IconAZ/>, "Azerbaijan"],
    "BA": [<IconBA/>, "Bosnia"/* and Herzegovina*/],
    "BB": [<IconBB/>, "Barbados"],
    "BD": [<IconBD/>, "Bangladesh"],
    "BE": [<IconBE/>, "Belgium"],
    "BF": [<IconBF/>, "Burkina Faso"],
    "BG": [<IconBG/>, "Bulgaria"],
    "BH": [<IconBH/>, "Bahrain"],
    "BI": [<IconBI/>, "Burundi"],
    "BJ": [<IconBJ/>, "Benin"],
    "BL": [<IconBL/>, "St. Barthélemy"/* Saint Barthélemy*/],
    "BM": [<IconBM/>, "Bermuda"],
    "BN": [<IconBN/>, "Brunei"/* Brunei Darussalam */],
    "BO": [<IconBO/>, "Bolivia"/* (Plurinational State of)*/],
    "BQ": [<IconBQ/>, "Bonaire"/*, Sint Eustatius and Saba*/],
    "BR": [<IconBR/>, "Brazil"],
    "BS": [<IconBS/>, "Bahamas"],
    "BT": [<IconBT/>, "Bhutan"],
    "BW": [<IconBW/>, "Botswana"],
    "BY": [<IconBY/>, "Belarus"],
    "BZ": [<IconBZ/>, "Belize"],
    "CA": [<IconCA/>, "Canada"],
    "CC": [<IconCC/>, "Cocos Isl."/* (Keeling) Islands*/],
    "CD": [<IconCD/>, "Congo"/*, Democratic Republic of the*/],
    "CF": [<IconCF/>, "C. African Rep."/* Central African Republic*/],
    "CG": [<IconCG/>, "Congo"],
    "CH": [<IconCH/>, "Switzerland"],
    "CK": [<IconCK/>, "Cook Isl."/* Cook Islands*/],
    "CL": [<IconCL/>, "Chile"],
    "CM": [<IconCM/>, "Cameroon"],
    "CN": [<IconCN/>, "China"],
    "CO": [<IconCO/>, "Colombia"],
    "CR": [<IconCR/>, "Costa Rica"],
    "CU": [<IconCU/>, "Cuba"],
    "CW": [<IconCW/>, "Curaçao"],
    "CX": [<IconCX/>, "Christmas Isl."/* Christmas Island*/],
    "CY": [<IconCY/>, "Cyprus"],
    "CZ": [<IconCZ/>, "Czechia"],
    "DE": [<IconDE/>, "Germany"],
    "DJ": [<IconDJ/>, "Djibouti"],
    "DK": [<IconDK/>, "Denmark"],
    "DM": [<IconDM/>, "Dominica"],
    "DO": [<IconDO/>, "Dominican Rep."/* Dominican Republic*/],
    "DZ": [<IconDZ/>, "Algeria"],
    "EC": [<IconEC/>, "Ecuador"],
    "EE": [<IconEE/>, "Estonia"],
    "EG": [<IconEG/>, "Egypt"],
    "EH": [<IconEH/>, "Western Sahara"],
    "ER": [<IconER/>, "Eritrea"],
    "ES": [<IconES/>, "Spain"],
    "ET": [<IconET/>, "Ethiopia"],
    "FI": [<IconFI/>, "Finland"],
    "FJ": [<IconFJ/>, "Fiji"],
    "FK": [<IconFK/>, "Malvinas"/*Falkland Islands (Malvinas)*/],
    "FM": [<IconFM/>, "Micronesia"/* (Federated States of)*/],
    "FO": [<IconFO/>, "Faroe Islands"],
    "FR": [<IconFR/>, "France"],
    "GA": [<IconGA/>, "Gabon"],
    "GB": [<IconGB/>, "United Kingdom"/* of Great Britain and Northern Ireland*/],
    "GD": [<IconGD/>, "Grenada"],
    "GE": [<IconGE/>, "Georgia"],
    "GG": [<IconGG/>, "Guernsey"],
    "GH": [<IconGH/>, "Ghana"],
    "GI": [<IconGI/>, "Gibraltar"],
    "GL": [<IconGL/>, "Greenland"],
    "GM": [<IconGM/>, "Gambia"],
    "GN": [<IconGN/>, "Guinea"],
    "GQ": [<IconGQ/>, "Eq. Guinea"/* Equatorial Guinea*/],
    "GR": [<IconGR/>, "Greece"],
    "GT": [<IconGT/>, "Guatemala"],
    "GU": [<IconGU/>, "Guam"],
    "GW": [<IconGW/>, "Guinea-Bissau"],
    "GY": [<IconGY/>, "Guyana"],
    "HK": [<IconHK/>, "Hong Kong"],
    "HN": [<IconHN/>, "Honduras"],
    "HR": [<IconHR/>, "Croatia"],
    "HT": [<IconHT/>, "Haiti"],
    "HU": [<IconHU/>, "Hungary"],
    "ID": [<IconID/>, "Indonesia"],
    "IE": [<IconIE/>, "Ireland"],
    "IL": [<IconIL/>, "Israel"],
    "IM": [<IconIM/>, "Isle of Man"],
    "IN": [<IconIN/>, "India"],
    "IO": [<IconIO/>, "Brit. Indian"/* British Indian Ocean Territory*/],
    "IQ": [<IconIQ/>, "Iraq"],
    "IR": [<IconIR/>, "Iran"/* (Islamic Republic of)*/],
    "IS": [<IconIS/>, "Iceland"],
    "IT": [<IconIT/>, "Italy"],
    "JE": [<IconJE/>, "Jersey"],
    "JM": [<IconJM/>, "Jamaica"],
    "JO": [<IconJO/>, "Jordan"],
    "JP": [<IconJP/>, "Japan"],
    "KE": [<IconKE/>, "Kenya"],
    "KG": [<IconKG/>, "Kyrgyzstan"],
    "KH": [<IconKH/>, "Cambodia"],
    "KI": [<IconKI/>, "Kiribati"],
    "KM": [<IconKM/>, "Comoros"],
    "KN": [<IconKN/>, "Saint Kitts"/* and Nevis*/],
    "KP": [<IconKP/>, "North Korea"/* Korea (Democratic People's Republic of)*/],
    "KR": [<IconKR/>, "South Korea"/* Korea, Republic of*/],
    "KW": [<IconKW/>, "Kuwait"],
    "KY": [<IconKY/>, "Cayman Isl."/* Cayman Islands*/],
    "KZ": [<IconKZ/>, "Kazakhstan"],
    "LA": [<IconLA/>, "Lao"/* People's Democratic Republic*/],
    "LB": [<IconLB/>, "Lebanon"],
    "LC": [<IconLC/>, "Saint Lucia"],
    "LI": [<IconLI/>, "Liechtenstein"],
    "LK": [<IconLK/>, "Sri Lanka"],
    "LR": [<IconLR/>, "Liberia"],
    "LS": [<IconLS/>, "Lesotho"],
    "LT": [<IconLT/>, "Lithuania"],
    "LU": [<IconLU/>, "Luxembourg"],
    "LV": [<IconLV/>, "Latvia"],
    "LY": [<IconLY/>, "Libya"],
    "MA": [<IconMA/>, "Morocco"],
    "MC": [<IconMC/>, "Monaco"],
    "MD": [<IconMD/>, "Moldova"/*, Republic of*/],
    "ME": [<IconME/>, "Montenegro"],
    "MG": [<IconMG/>, "Madagascar"],
    "MH": [<IconMH/>, "Marshall Isl."/* Marshall Islands*/],
    "MK": [<IconMK/>, "N. Macedonia"/* North Macedonia*/],
    "ML": [<IconML/>, "Mali"],
    "MM": [<IconMM/>, "Myanmar"],
    "MN": [<IconMN/>, "Mongolia"],
    "MO": [<IconMO/>, "Macao"],
    "MP": [<IconMP/>, "Mariana Isl."/* Northern Mariana Islands*/],
    "MQ": [<IconMQ/>, "Martinique"],
    "MR": [<IconMR/>, "Mauritania"],
    "MS": [<IconMS/>, "Montserrat"],
    "MT": [<IconMT/>, "Malta"],
    "MU": [<IconMU/>, "Mauritius"],
    "MV": [<IconMV/>, "Maldives"],
    "MW": [<IconMW/>, "Malawi"],
    "MX": [<IconMX/>, "Mexico"],
    "MY": [<IconMY/>, "Malaysia"],
    "MZ": [<IconMZ/>, "Mozambique"],
    "NA": [<IconNA/>, "Namibia"],
    "NE": [<IconNE/>, "Niger"],
    "NF": [<IconNF/>, "Norfolk Isl."],
    "NG": [<IconNG/>, "Nigeria"],
    "NI": [<IconNI/>, "Nicaragua"],
    "NL": [<IconNL/>, "Netherlands"/*, Kingdom of the*/],
    "NO": [<IconNO/>, "Norway"],
    "NP": [<IconNP/>, "Nepal"],
    "NR": [<IconNR/>, "Nauru"],
    "NU": [<IconNU/>, "Niue"],
    "NZ": [<IconNZ/>, "New Zealand"],
    "OM": [<IconOM/>, "Oman"],
    "PA": [<IconPA/>, "Panama"],
    "PE": [<IconPE/>, "Peru"],
    "PF": [<IconPF/>, "Polynesia"/* French Polynesia*/],
    "PG": [<IconPG/>, "New Guinea"/* Papua New Guinea*/],
    "PH": [<IconPH/>, "Philippines"],
    "PK": [<IconPK/>, "Pakistan"],
    "PL": [<IconPL/>, "Poland"],
    "PN": [<IconPN/>, "Pitcairn"],
    "PR": [<IconPR/>, "Puerto Rico"],
    "PS": [<IconPS/>, "Palestine"/*, State of*/],
    "PT": [<IconPT/>, "Portugal"],
    "PW": [<IconPW/>, "Palau"],
    "PY": [<IconPY/>, "Paraguay"],
    "QA": [<IconQA/>, "Qatar"],
    "RO": [<IconRO/>, "Romania"],
    "RS": [<IconRS/>, "Serbia"],
    "RU": [<IconRU/>, "Russia"/* Russian Federation*/],
    "RW": [<IconRW/>, "Rwanda"],
    "SA": [<IconSA/>, "Saudi Arabia"],
    "SB": [<IconSB/>, "Solomon Isl."/* Solomon Islands*/],
    "SC": [<IconSC/>, "Seychelles"],
    "SD": [<IconSD/>, "Sudan"/* The Republic of Sudan | North Sudan*/],
    "SE": [<IconSE/>, "Sweden"],
    "SG": [<IconSG/>, "Singapore"],
    "SI": [<IconSI/>, "Slovenia"],
    "SK": [<IconSK/>, "Slovakia"],
    "SL": [<IconSL/>, "Sierra Leone"],
    "SM": [<IconSM/>, "San Marino"],
    "SN": [<IconSN/>, "Senegal"],
    "SO": [<IconSO/>, "Somalia"],
    "SR": [<IconSR/>, "Suriname"],
    "SS": [<IconSS/>, "South Sudan"],
    "ST": [<IconST/>, "Sao Tome"/* and Principe*/],
    "SV": [<IconSV/>, "El Salvador"],
    "SX": [<IconSX/>, "Sint Maarten"/* (Dutch part)*/],
    "SY": [<IconSY/>, "Syria"/* Syrian Arab Republic*/],
    "SZ": [<IconSZ/>, "Eswatini"],
    "TC": [<IconTC/>, "Turks Isl."/* and Caicos Islands*/],
    "TD": [<IconTD/>, "Chad"],
    "TG": [<IconTG/>, "Togo"],
    "TH": [<IconTH/>, "Thailand"],
    "TJ": [<IconTJ/>, "Tajikistan"],
    "TK": [<IconTK/>, "Tokelau"],
    "TL": [<IconTL/>, "Timor-Leste"],
    "TM": [<IconTM/>, "Turkmenistan"],
    "TN": [<IconTN/>, "Tunisia"],
    "TO": [<IconTO/>, "Tonga"],
    "TR": [<IconTR/>, "Türkiye"],
    "TT": [<IconTT/>, "Trinidad"/* and Tobago*/],
    "TV": [<IconTV/>, "Tuvalu"],
    "TW": [<IconTW/>, "Taiwan"/*, Province of China*/],
    "TZ": [<IconTZ/>, "Tanzania"/*, United Republic of*/],
    "UA": [<IconUA/>, "Ukraine"],
    "UG": [<IconUG/>, "Uganda"],
    "US": [<IconUS/>, "United States"/* of America*/],
    "UY": [<IconUY/>, "Uruguay"],
    "UZ": [<IconUZ/>, "Uzbekistan"],
    "VC": [<IconVC/>, "Saint Vincent"/* and the Grenadines*/],
    "VE": [<IconVE/>, "Venezuela"/* (Bolivarian Republic of)*/],
    "VG": [<IconVG/>, "Virgin Isl. (GB)"/* Virgin Islands (British)*/],
    "VI": [<IconVI/>, "Virgin Isl. (US)"/* Virgin Islands (U.S.)*/],
    "VN": [<IconVN/>, "Viet Nam"],
    "VU": [<IconVU/>, "Vanuatu"],
    "WS": [<IconWS/>, "Samoa"],
    "YE": [<IconYE/>, "Yemen"],
    "ZA": [<IconZA/>, "South Africa"],
    "ZM": [<IconZM/>, "Zambia"],
    "ZW": [<IconZW/>, "Zimbabwe"],

    // Custom
    "UN": [<IconUN/>, "World"],
    "Undefined": [<IconUndefined/>, "Undefined"],
    /* TODO: Remove previous redundant block */



    "ad": [<IconAD/>, "Andorra"],
    "ae": [<IconAE/>, "Emirates"/* United Arab Emirates*/],
    "af": [<IconAF/>, "Afghanistan"],
    "ag": [<IconAG/>, "Antigua"/* and Barbuda*/],
    "ai": [<IconAI/>, "Anguilla"],
    "al": [<IconAL/>, "Albania"],
    "am": [<IconAM/>, "Armenia"],
    "ao": [<IconAO/>, "Angola"],
    "ar": [<IconAR/>, "Argentina"],
    "as": [<IconAS/>, "American Samoa"],
    "at": [<IconAT/>, "Austria"],
    "au": [<IconAU/>, "Australia"],
    "aw": [<IconAW/>, "Aruba"],
    "ax": [<IconAX/>, "Åland Islands"],
    "az": [<IconAZ/>, "Azerbaijan"],
    "ba": [<IconBA/>, "Bosnia"/* and Herzegovina*/],
    "bb": [<IconBB/>, "Barbados"],
    "bd": [<IconBD/>, "Bangladesh"],
    "be": [<IconBE/>, "Belgium"],
    "bf": [<IconBF/>, "Burkina Faso"],
    "bg": [<IconBG/>, "Bulgaria"],
    "bh": [<IconBH/>, "Bahrain"],
    "bi": [<IconBI/>, "Burundi"],
    "bj": [<IconBJ/>, "Benin"],
    "bl": [<IconBL/>, "St. Barthélemy"/* Saint Barthélemy*/],
    "bm": [<IconBM/>, "Bermuda"],
    "bn": [<IconBN/>, "Brunei"/* Brunei Darussalam */],
    "bo": [<IconBO/>, "Bolivia"/* (Plurinational State of)*/],
    "bq": [<IconBQ/>, "Bonaire"/*, Sint Eustatius and Saba*/],
    "br": [<IconBR/>, "Brazil"],
    "bs": [<IconBS/>, "Bahamas"],
    "bt": [<IconBT/>, "Bhutan"],
    "bw": [<IconBW/>, "Botswana"],
    "by": [<IconBY/>, "Belarus"],
    "bz": [<IconBZ/>, "Belize"],
    "ca": [<IconCA/>, "Canada"],
    "cc": [<IconCC/>, "Cocos Isl."/* (Keeling) Islands*/],
    "cd": [<IconCD/>, "Congo"/*, Democratic Republic of the*/],
    "cf": [<IconCF/>, "C. African Rep."/* Central African Republic*/],
    "cg": [<IconCG/>, "Congo"],
    "ch": [<IconCH/>, "Switzerland"],
    "ck": [<IconCK/>, "Cook Isl."/* Cook Islands*/],
    "cl": [<IconCL/>, "Chile"],
    "cm": [<IconCM/>, "Cameroon"],
    "cn": [<IconCN/>, "China"],
    "co": [<IconCO/>, "Colombia"],
    "cr": [<IconCR/>, "Costa Rica"],
    "cu": [<IconCU/>, "Cuba"],
    "cw": [<IconCW/>, "Curaçao"],
    "cx": [<IconCX/>, "Christmas Isl."/* Christmas Island*/],
    "cy": [<IconCY/>, "Cyprus"],
    "cz": [<IconCZ/>, "Czechia"],
    "de": [<IconDE/>, "Germany"],
    "dj": [<IconDJ/>, "Djibouti"],
    "dk": [<IconDK/>, "Denmark"],
    "dm": [<IconDM/>, "Dominica"],
    "do": [<IconDO/>, "Dominican Rep."/* Dominican Republic*/],
    "dz": [<IconDZ/>, "Algeria"],
    "ec": [<IconEC/>, "Ecuador"],
    "ee": [<IconEE/>, "Estonia"],
    "eg": [<IconEG/>, "Egypt"],
    "eh": [<IconEH/>, "Western Sahara"],
    "er": [<IconER/>, "Eritrea"],
    "es": [<IconES/>, "Spain"],
    "et": [<IconET/>, "Ethiopia"],
    "fi": [<IconFI/>, "Finland"],
    "fj": [<IconFJ/>, "Fiji"],
    "fk": [<IconFK/>, "Malvinas"/*Falkland Islands (Malvinas)*/],
    "fm": [<IconFM/>, "Micronesia"/* (Federated States of)*/],
    "fo": [<IconFO/>, "Faroe Islands"],
    "fr": [<IconFR/>, "France"],
    "ga": [<IconGA/>, "Gabon"],
    "gb": [<IconGB/>, "United Kingdom"/* of Great Britain and Northern Ireland*/],
    "gd": [<IconGD/>, "Grenada"],
    "ge": [<IconGE/>, "Georgia"],
    "gg": [<IconGG/>, "Guernsey"],
    "gh": [<IconGH/>, "Ghana"],
    "gi": [<IconGI/>, "Gibraltar"],
    "gl": [<IconGL/>, "Greenland"],
    "gm": [<IconGM/>, "Gambia"],
    "gn": [<IconGN/>, "Guinea"],
    "gq": [<IconGQ/>, "Eq. Guinea"/* Equatorial Guinea*/],
    "gr": [<IconGR/>, "Greece"],
    "gt": [<IconGT/>, "Guatemala"],
    "gu": [<IconGU/>, "Guam"],
    "gw": [<IconGW/>, "Guinea-Bissau"],
    "gy": [<IconGY/>, "Guyana"],
    "hk": [<IconHK/>, "Hong Kong"],
    "hn": [<IconHN/>, "Honduras"],
    "hr": [<IconHR/>, "Croatia"],
    "ht": [<IconHT/>, "Haiti"],
    "hu": [<IconHU/>, "Hungary"],
    "id": [<IconID/>, "Indonesia"],
    "ie": [<IconIE/>, "Ireland"],
    "il": [<IconIL/>, "Israel"],
    "im": [<IconIM/>, "Isle of Man"],
    "in": [<IconIN/>, "India"],
    "io": [<IconIO/>, "Brit. Indian"/* British Indian Ocean Territory*/],
    "iq": [<IconIQ/>, "Iraq"],
    "ir": [<IconIR/>, "Iran"/* (Islamic Republic of)*/],
    "is": [<IconIS/>, "Iceland"],
    "it": [<IconIT/>, "Italy"],
    "je": [<IconJE/>, "Jersey"],
    "jm": [<IconJM/>, "Jamaica"],
    "jo": [<IconJO/>, "Jordan"],
    "jp": [<IconJP/>, "Japan"],
    "ke": [<IconKE/>, "Kenya"],
    "kg": [<IconKG/>, "Kyrgyzstan"],
    "kh": [<IconKH/>, "Cambodia"],
    "ki": [<IconKI/>, "Kiribati"],
    "km": [<IconKM/>, "Comoros"],
    "kn": [<IconKN/>, "Saint Kitts"/* and Nevis*/],
    "kp": [<IconKP/>, "North Korea"/* Korea (Democratic People's Republic of)*/],
    "kr": [<IconKR/>, "South Korea"/* Korea, Republic of*/],
    "kw": [<IconKW/>, "Kuwait"],
    "ky": [<IconKY/>, "Cayman Isl."/* Cayman Islands*/],
    "kz": [<IconKZ/>, "Kazakhstan"],
    "la": [<IconLA/>, "Lao"/* People's Democratic Republic*/],
    "lb": [<IconLB/>, "Lebanon"],
    "lc": [<IconLC/>, "Saint Lucia"],
    "li": [<IconLI/>, "Liechtenstein"],
    "lk": [<IconLK/>, "Sri Lanka"],
    "lr": [<IconLR/>, "Liberia"],
    "ls": [<IconLS/>, "Lesotho"],
    "lt": [<IconLT/>, "Lithuania"],
    "lu": [<IconLU/>, "Luxembourg"],
    "lv": [<IconLV/>, "Latvia"],
    "ly": [<IconLY/>, "Libya"],
    "ma": [<IconMA/>, "Morocco"],
    "mc": [<IconMC/>, "Monaco"],
    "md": [<IconMD/>, "Moldova"/*, Republic of*/],
    "me": [<IconME/>, "Montenegro"],
    "mg": [<IconMG/>, "Madagascar"],
    "mh": [<IconMH/>, "Marshall Isl."/* Marshall Islands*/],
    "mk": [<IconMK/>, "N. Macedonia"/* North Macedonia*/],
    "ml": [<IconML/>, "Mali"],
    "mm": [<IconMM/>, "Myanmar"],
    "mn": [<IconMN/>, "Mongolia"],
    "mo": [<IconMO/>, "Macao"],
    "mp": [<IconMP/>, "Mariana Isl."/* Northern Mariana Islands*/],
    "mq": [<IconMQ/>, "Martinique"],
    "mr": [<IconMR/>, "Mauritania"],
    "ms": [<IconMS/>, "Montserrat"],
    "mt": [<IconMT/>, "Malta"],
    "mu": [<IconMU/>, "Mauritius"],
    "mv": [<IconMV/>, "Maldives"],
    "mw": [<IconMW/>, "Malawi"],
    "mx": [<IconMX/>, "Mexico"],
    "my": [<IconMY/>, "Malaysia"],
    "mz": [<IconMZ/>, "Mozambique"],
    "na": [<IconNA/>, "Namibia"],
    "ne": [<IconNE/>, "Niger"],
    "nf": [<IconNF/>, "Norfolk Isl."],
    "ng": [<IconNG/>, "Nigeria"],
    "ni": [<IconNI/>, "Nicaragua"],
    "nl": [<IconNL/>, "Netherlands"/*, Kingdom of the*/],
    "no": [<IconNO/>, "Norway"],
    "np": [<IconNP/>, "Nepal"],
    "nr": [<IconNR/>, "Nauru"],
    "nu": [<IconNU/>, "Niue"],
    "nz": [<IconNZ/>, "New Zealand"],
    "om": [<IconOM/>, "Oman"],
    "pa": [<IconPA/>, "Panama"],
    "pe": [<IconPE/>, "Peru"],
    "pf": [<IconPF/>, "Polynesia"/* French Polynesia*/],
    "pg": [<IconPG/>, "New Guinea"/* Papua New Guinea*/],
    "ph": [<IconPH/>, "Philippines"],
    "pk": [<IconPK/>, "Pakistan"],
    "pl": [<IconPL/>, "Poland"],
    "pn": [<IconPN/>, "Pitcairn"],
    "pr": [<IconPR/>, "Puerto Rico"],
    "ps": [<IconPS/>, "Palestine"/*, State of*/],
    "pt": [<IconPT/>, "Portugal"],
    "pw": [<IconPW/>, "Palau"],
    "py": [<IconPY/>, "Paraguay"],
    "qa": [<IconQA/>, "Qatar"],
    "ro": [<IconRO/>, "Romania"],
    "rs": [<IconRS/>, "Serbia"],
    "ru": [<IconRU/>, "Russia"/* Russian Federation*/],
    "rw": [<IconRW/>, "Rwanda"],
    "sa": [<IconSA/>, "Saudi Arabia"],
    "sb": [<IconSB/>, "Solomon Isl."/* Solomon Islands*/],
    "sc": [<IconSC/>, "Seychelles"],
    "sd": [<IconSD/>, "Sudan"/* The Republic of Sudan | North Sudan*/],
    "se": [<IconSE/>, "Sweden"],
    "sg": [<IconSG/>, "Singapore"],
    "si": [<IconSI/>, "Slovenia"],
    "sk": [<IconSK/>, "Slovakia"],
    "sl": [<IconSL/>, "Sierra Leone"],
    "sm": [<IconSM/>, "San Marino"],
    "sn": [<IconSN/>, "Senegal"],
    "so": [<IconSO/>, "Somalia"],
    "sr": [<IconSR/>, "Suriname"],
    "ss": [<IconSS/>, "South Sudan"],
    "st": [<IconST/>, "Sao Tome"/* and Principe*/],
    "sv": [<IconSV/>, "El Salvador"],
    "sx": [<IconSX/>, "Sint Maarten"/* (Dutch part)*/],
    "sy": [<IconSY/>, "Syria"/* Syrian Arab Republic*/],
    "sz": [<IconSZ/>, "Eswatini"],
    "tc": [<IconTC/>, "Turks Isl."/* and Caicos Islands*/],
    "td": [<IconTD/>, "Chad"],
    "tg": [<IconTG/>, "Togo"],
    "th": [<IconTH/>, "Thailand"],
    "tj": [<IconTJ/>, "Tajikistan"],
    "tk": [<IconTK/>, "Tokelau"],
    "tl": [<IconTL/>, "Timor-Leste"],
    "tm": [<IconTM/>, "Turkmenistan"],
    "tn": [<IconTN/>, "Tunisia"],
    "to": [<IconTO/>, "Tonga"],
    "tr": [<IconTR/>, "Türkiye"],
    "tt": [<IconTT/>, "Trinidad"/* and Tobago*/],
    "tv": [<IconTV/>, "Tuvalu"],
    "tw": [<IconTW/>, "Taiwan"/*, Province of China*/],
    "tz": [<IconTZ/>, "Tanzania"/*, United Republic of*/],
    "ua": [<IconUA/>, "Ukraine"],
    "ug": [<IconUG/>, "Uganda"],
    "us": [<IconUS/>, "United States"/* of America*/],
    "uy": [<IconUY/>, "Uruguay"],
    "uz": [<IconUZ/>, "Uzbekistan"],
    "vc": [<IconVC/>, "Saint Vincent"/* and the Grenadines*/],
    "ve": [<IconVE/>, "Venezuela"/* (Bolivarian Republic of)*/],
    "vg": [<IconVG/>, "Virgin Isl. (GB)"/* Virgin Islands (British)*/],
    "vi": [<IconVI/>, "Virgin Isl. (US)"/* Virgin Islands (U.S.)*/],
    "vn": [<IconVN/>, "Viet Nam"],
    "vu": [<IconVU/>, "Vanuatu"],
    "ws": [<IconWS/>, "Samoa"],
    "ye": [<IconYE/>, "Yemen"],
    "za": [<IconZA/>, "South Africa"],
    "zm": [<IconZM/>, "Zambia"],
    "zw": [<IconZW/>, "Zimbabwe"],

    // Custom
    "un": [<IconUN/>, "World"],
    "undefined": [<IconUndefined/>, "Undefined"],
    "all_locations": [<IconUN/>, "All locations"],
}
const device = {
    "android": [<IconAndroid/>, "Android"],
    "iphone": [<IconIPhone/>, "iPhone"],
    "windows_pc": [<IconPC/>, "Windows PC"],
    "macos": [<IconMac/>, "MacOS"],
    "other_device": [<IconUnknown/>, "Other device"],
    "all_devices": [<IconUnknown/>, "All devices"],
    "all_device": [<IconUnknown/>, "All devices"]
}

const IAB = {
    "all_iab": [<IconUnknown/>, "All IABs"],
    "all_iabs": [<IconUnknown/>, "All IABs"],
    "all_category": [<IconUnknown/>, "All category"],
    "all_categories": [<IconUnknown/>, "All categories"],
    "automotive": [<IconUnknown/>, "Automotive"],
    "business": [<IconUnknown/>, "Business"],
    "careers": [<IconUnknown/>, "Careers"],
    "education": [<IconUnknown/>, "Education"],
    "family_&_parenting": [<IconUnknown/>, "Family & Parenting"],
    "health_&_fitness": [<IconUnknown/>, "Health & Fitness"],
    "food_&_drink": [<IconUnknown/>, "Food & Drink"],
    "hobbies_&_interests": [<IconUnknown/>, "Hobbies & Interests"],
}

const time = {
    "0am": [<IconAm/>, "0 AM"],
    "1am": [<IconAm/>, "1 AM"],
    "2am": [<IconAm/>, "2 AM"],
    "3am": [<IconAm/>, "3 AM"],
    "4am": [<IconAm/>, "4 AM"],
    "5am": [<IconAm/>, "5 AM"],
    "6am": [<IconAm/>, "6 AM"],
    "7am": [<IconAm/>, "7 AM"],
    "8am": [<IconAm/>, "8 AM"],
    "9am": [<IconAm/>, "9 AM"],
    "10am": [<IconAm/>, "10 AM"],
    "11am": [<IconAm/>, "11 AM"],
    "12am": [<IconAm/>, "12 AM"],
    "0pm": [<IconPm/>, "0 PM"],
    "1pm": [<IconPm/>, "1 PM"],
    "2pm": [<IconPm/>, "2 PM"],
    "3pm": [<IconPm/>, "3 PM"],
    "4pm": [<IconPm/>, "4 PM"],
    "5pm": [<IconPm/>, "5 PM"],
    "6pm": [<IconPm/>, "6 PM"],
    "7pm": [<IconPm/>, "7 PM"],
    "8pm": [<IconPm/>, "8 PM"],
    "9pm": [<IconPm/>, "9 PM"],
    "10pm": [<IconPm/>, "10 PM"],
    "11pm": [<IconPm/>, "11 PM"],
    "12pm": [<IconPm/>, "12 PM"],
    "all_time": [<IconUnknown/>, "All times"],
    "all_times": [<IconUnknown/>, "All times"],
    "all_timing": [<IconUnknown/>, "All timing"]
}

const content = {
    "all_genres": [<IconUnknown/>, "All genres"],
    "unknown": [<IconUnknown/>, "Unknown"],
    // "other_content": [<IconUnknown/>, "Other content"],
    "other_category": [<IconUnknown/>, "Other category"],
    "bike": [<IconUnknown/>, "Bike"],
    "minecraft": [<IconUnknown/>, "Minecraft"],
    "casual": [<IconUnknown/>, "Casual"],
    "racing": [<IconUnknown/>, "Racing"],
    "simulator": [<IconUnknown/>, "Simulator"],
    "shooter": [<IconUnknown/>, "Shooter"],
    "puzzle": [<IconUnknown/>, "Puzzle"],
    "sport": [<IconUnknown/>, "Sport"],
    "single_player": [<IconUnknown/>, "Single player"],
    "action": [<IconUnknown/>, "Action"],

    "all_category": [<IconUnknown/>, "All categories"],
    "all_categories": [<IconUnknown/>, "All categories"],
    "all_content": [<IconUnknown/>, "All content"],
    "all_genres": [<IconUnknown/>, "All genres"],
    "unknown": [<IconUnknown/>, "Unknown"],
    "action": [<IconUnknown/>, "Action"],
    "drama": [<IconUnknown/>, "Drama"],
    "horror": [<IconUnknown/>, "Horror"],
    "documentaries": [<IconUnknown/>, "Documentaries"],
    "comedies": [<IconUnknown/>, "Comedies"],
    "romance": [<IconUnknown/>, "Romance"],
    "sci-fi": [<IconUnknown/>, "Sci-Fi"],
    "sports": [<IconUnknown/>, "Sports"]
    
    // "all_genres": [<IconUnknown/>, "All genres"],
    // "unknown": [<IconUnknown/>, "Unknown"],
    // "other_content": [<IconUnknown/>, "Other content"],
    // "bike": [<IconUnknown/>, "Bike"],
    // "minecraft": [<IconUnknown/>, "Minecraft"],
    // "casual": [<IconUnknown/>, "Casual"],
    // "racing": [<IconUnknown/>, "Racing"],
    // "simulator": [<IconUnknown/>, "Simulator"],
    // "shooter": [<IconUnknown/>, "Shooter"],
    // "puzzle": [<IconUnknown/>, "Puzzle"],
    // "sport": [<IconUnknown/>, "Sport"],
    // "single_player": [<IconUnknown/>, "Single player"],
    // "action": [<IconUnknown/>, "Action"]
}

const persona = {
    "all_audiences": [PicPersonaF45c, "All audiences"],
    "f15c": [PicPersonaF15c, "Jessica Miller"],
    "f20c": [PicPersonaF20c, "Emily Johnson"],
    "f25c": [PicPersonaF25c, "Sarah Brown"],
    "f30c": [PicPersonaF30c, "Jennifer Taylor"],
    "f35c": [PicPersonaF35c, "Ashley Doe"],
    "f40c": [PicPersonaF40c, "Jane Wilson"],
    "f45c": [PicPersonaF45c, "Lisa Thompson"],
    "f50c": [PicPersonaF50c, "Linda White"],
    "f55c": [PicPersonaF55c, "Carol Lewis"],
    "f60c": [PicPersonaF60c, "Ruth Walker"],
    "f65c": [PicPersonaF65c, "Sharon Hall"],
    "f70c": [PicPersonaF70c, "Patricia Anderson"],
    "f75c": [PicPersonaF75c, "Susan Harris"],
    "f80c": [PicPersonaF80c, "Betty Clark"],
    "m15c": [PicPersonaM15c, "Michael Green"],
    "m20c": [PicPersonaM20c, "David Jones"],
    "m25c": [PicPersonaM25c, "James Smith"],
    "m30c": [PicPersonaM30c, "Robert Davis"],
    "m35c": [PicPersonaM35c, "William Moore"],
    "m40c": [PicPersonaM40c, "Richard Taylor"],
    "m45c": [PicPersonaM45c, "Charles Thomas"],
    "m50c": [PicPersonaM50c, "Joseph Jackson"],
    "m55c": [PicPersonaM55c, "Christopher Harris"],
    "m60c": [PicPersonaM60c, "Daniel Martin"],
    "m65c": [PicPersonaM65c, "Paul Thompson"],
    "m70c": [PicPersonaM70c, "Mark Anderson"],
    "m75c": [PicPersonaM75c, "Donald Lewis"],
    "m80c": [PicPersonaM80c, "Jason Scott"]
}

// TODO: Use array matching with "mode" prop [instead of "heap" and/or "textlesstemp"] and/or "[...].includes(what)" instead of implicit "type" detection
export const Insert = ({what, heap, size=22, callback, textlesstemp}) => {
    const isNotANumber = isNaN(what)

    // const isArray = Array.isArray(what)
    const isString = typeof what === "string"

    if (isString) what = what.toLowerCase().replaceAll(" ", "_")

    // TODO: Clean up everything in order to support other string-key'd icon collections (e.g. 'what in device', 'device.hasOwnProperty(what)', '!!device[what]')
    const isDevice = ["android", "iphone", "windows_pc", "macos", "other_device", "all_devices", "all_device"].includes(what)
   
    // TODO: Either just detect the am/pm suffix or use 'time[what]' or 'time.hasOwnProperty(what)' instead
    const isTime = ["0am", "1am", "2am", "3am", "4am", "5am", "6am", "7am", "8am","9am", "10am", "11am", "12am", "0pm", "1pm", "2pm", "3pm", "4pm", "5pm", "6pm","7pm", "8pm", "9pm", "10pm", "11pm", "12pm", "all_times", "all_time", "all_timing"].includes(what)

    // TODO: Same as above
    // const isContent = ["all_genres", "unknown", "other_content", "bike", "minecraft", "casual", "racing", "simulator", "shooter", "puzzle", "sport", "single_player", "action"].includes(what)
    const isContent = ["all_categories", "all_category", "unknown", "other_category", "bike", "minecraft", "casual", "racing", "simulator", "shooter", "puzzle", "sport", "single_player", "action",     "unknown", "action", "drama", "horror", "documentaries", "comedies", "romance", "sci-fi", "sports"].includes(what)
    const isIAB = ["all_iabs", "all_iab", "automotive", "business", "careers", "education", "family_&_parenting", "health_&_fitness", "food_&_drink", "hobbies_&_interests"].includes(what)

    // Fallback w/ no icon (almost zero performance impact [below ~10ms per million calls])
    if (isString && !(IAB.hasOwnProperty(what) || content.hasOwnProperty(what) || time.hasOwnProperty(what) || device.hasOwnProperty(what) || country.hasOwnProperty(what) || persona.hasOwnProperty(what))) return what

    return (
        <div className={`insert ${heap ? "insert--heap" : (heap === null ? "insert--kiss" : "")} ${size > 22 ? "dummy-temp-test" : ""} ${textlesstemp ? "textlesstemp" : ""} `} style={{"--__insert-orb-diameter": size + "px"}}>{
            isTime/* TODO: This is yet another patch (have some robust logic instead) */ || isContent/* TODO: Same */ || isIAB || (isNotANumber && isNaN(what?.[0]))// ...so is not Heartbeat
            ?
                isString
                ?

                /* TODO: Refactor all this (i.e. clean messy workaround) */
                <div className={`${(isDevice || isTime || isContent || isIAB) ? "" : "insert-orb"} insert-one`}>
                    {
                    persona[what] ? 
                    <>
                        <img src={persona[what][0]} alt={persona[what][1]}/>
                        {textlesstemp ? "" : persona[what][1]}
                    </>
                    : 
                    <>
                        {/* TODO: Proper logic here */}
                        {isIAB ? IAB[what]?.[0] : (isContent ? content[what]?.[0] : (isTime ? time[what]?.[0] : (isDevice ? device[what]?.[0] : country[what]?.[0])))}
                        {isIAB ? IAB[what]?.[1] : (isContent ? content[what]?.[1] : (isTime ? time[what]?.[1] : (isDevice ? device[what]?.[1] : (textlesstemp ? "" : country[what]?.[1]))))}
                    </>
                    }
                </div>
                :
                <>

                {/* TODO: Find a better alignment system (this is just an ugly workaround) */}
                <div className={"insert-orb insert-many"}>{
                    what?.map((item, index) => {
                        return <div key={index}>{
                            item.length === 2 && item === item.toUpperCase() 
                            ?
                            // isDevice ? device[what]?.[0] : country[item]?.[0] 
                            country[item]?.[0] 
                            :
                            <img src={persona[item][0]} alt={persona[item][1]}/> 
                        }</div>
                    })
                }</div>
                </>
            : 
                isNotANumber// -> So is a expandable range [b/c the first item isn't a number either]
                ?
                // TODO: Ellipsis support for the "L/M/H engagement" text
                <div className={`insert-heartbeat insert-heartbeat-range`}>
                    {/* <IconHB className="heartbeat-icon"/> */}
                    <Heartbeat/>
                    <span onClick={callback}>{what?.join("‐"/* TODO: Support 'minus' symbol instead [vs this U+2010 or U+2011 or &#x2010;] w/o sorting problems b/c of ~regex */)}</span>
                    <span className="aaaa text-xxs">&nbsp;→ {what[0] ? (what[0] > 50 ? "High" : "Mid") : "Low"} engagement</span>
                </div>
                :
                <div className={`insert-heartbeat`}>
                    {/* <IconHB className="heartbeat-icon"/> */}
                    <Heartbeat/>
                    <span>{what}</span>
                </div>
                }
        </div>
    )
}
