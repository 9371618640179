import "./Checkbox.css"

export const Checkbox = ({callback}) => {
  return (
    <div className="checkbox" onClick={(event) => {
      const target = event.currentTarget.firstElementChild
      event.preventDefault()
      target.checked = !target.checked
      callback(target.checked)
      }}>
      <input className="checkbox__toggle__checkbox" id="checkbox" type="checkbox"/>
      <label className="checkbox__toggle" htmlFor="checkbox"/>
    </div>
  )
}