import {
  Counter
} from "elements"

// ALT to the default unicode arrows style
// import { ReactComponent as IconArrowUp } from "assets/icons/small/arrow_up.svg"
// import { ReactComponent as IconArrowDn } from "assets/icons/small/arrow_dn.svg"

import "./Tick.css"

export const Tick = ({value, tag}) => {

  const num = !Number(value) && value !== 0 ? Number(value.replace(/[^0-9.-]/g, "")) : value
  const [gtz, ltz] = [num > 0, num < 0]// Legacy -> const gtz = tag || (!Number(value) ? Number(value.replace(/[^0-9.-]/g, "")) : value) > 0

  return (
    <div className={`${"tick--tag-" + tag} tick ${gtz ? "tick--up" : (ltz ? "tick--dn" : "")} text-xs`}>
      {
      tag 
      ? 
      value
      :
      <>
        <span className={`tick-arrow`}>{`${gtz ? "↑" : (ltz ? "↓" : "")}`}</span>{/* ALT: <span className="tick-arrow-svg">{gtz ? <IconArrowUp/> : <IconArrowDn/>}</span> */}
        <Counter value={value}/>
      </>
      }
    </div>
  )
}