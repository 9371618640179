// Calculator-wide styling
import "./Calculator.css"

// Containers
import { 
    Layout
} from "containers"

// Calculator assembly
export const Calculator = () => {
    return (
      <Layout header={{
        "title": "Calculator",
        "search": ""
      }}>
        <b>Work in progress...</b><br/>
        <small>This section will be hidden before a new commit is pushed</small>
      </Layout>
)}