import { 
    useRef
} from "react"

// import { ReactComponent as MenuIcon } from 'assets/Menu.svg'
import { ReactComponent as NucleusIcon } from 'assets/logo-icon.svg'

// Legacy
// import dummySearchBar from "assets/_poc/1296x68_search_1_k.png"

// Styling
import "./Header.css"

export const Header = ({ title, callback }) => {
    const sidebarHidden = useRef(true)
    const mutableButton = useRef(null)

    return (
        <div className='header'>
            <div className='header__icon__wrapper'>
                <NucleusIcon/>
            </div>
            <div className="header__h2__wrapper">
                <h2 className='header__title'>{title}</h2>
            </div>
            <div className={`header__menu__wrapper ${sidebarHidden.current ? "header__menu__wrapper--toggle" : ""}`} onClick={() => {
                mutableButton.current.classList = "burger-animate " + (mutableButton.current.classList.contains("burger-close") ? "burger-menu" : "burger-close")
                callback(sidebarHidden.current = !sidebarHidden.current)
                }}>

                {/* /* TODO: Make a element component out of this block */}
                <div className="burger">
                    <div className={sidebarHidden.current ? "" : "burger-overlay"}></div>
                    <div ref={mutableButton} className="burger-menu"></div>
                </div>
            </div>
        </div>
    )
}

// === Legacy
// export const Header = ({ content, callback }) => {

//     return (
//         <div className="header">
//             <div className="header__h2__wrapper">
//                 {/* <h2 className={`header__title`}>{content.title}</h2>  */}
//                 {dummySearchBar //content.search
//                     ? 
//                     // <img className="_tempDummyHeaderImage" src={dummySearchBar/*content.search*/}/>
//                     <></>
//                     // <h2 className={`header__title header__title__loading`}>Loading...</h2> 
//                     : 
//                     <h2 className={`__header__title`}>{content.title}</h2> 
//                 }
//             </div>
//         </div>
//     )
// }




