import {
  BrowserRouter,
  Route,
  Routes,
  Navigate
} from "react-router-dom"

import {
  ProvideGlobals
} from "contexts"

// Views
import {
  Help,
  Settings,
  Home,
  Campaigns,
  Catalog,
  Calculator
} from "views"

import {
  Audiences,
  Locations,
  Category,
  IAB,
  Timing,
  Devices
} from "views/Campaigns"

// Util
import {
  ROUTE_PREFIX,
  ROUTE_HOME,
  ROUTE_CATALOG,
  ROUTE_CAMPAIGNS,
  ROUTE_CAMPAIGNS_01,
  ROUTE_CAMPAIGNS_02,
  ROUTE_CAMPAIGNS_03,
  ROUTE_CAMPAIGNS_04,
  ROUTE_CAMPAIGNS_05,
  ROUTE_CAMPAIGNS_06,
  ROUTE_HELP,
  ROUTE_SETTINGS,
  ROUTE_CALCULATOR
} from "util/Routes.const"

import {
  SHOW_N_ENABLE_ALL,
  ENABLE_ROUTE_HOME,
  ENABLE_ROUTE_CAMPAIGNS_INTEREST_CATEGORIES,
  ENABLE_ROUTE_CAMPAIGNS_DEVICE_PERFORMANCE,
  ENABLE_ROUTE_HELP,
  ENABLE_ROUTE_SETTINGS,
  ENABLE_CATALOG,
  ENABLE_ROUTE_CALCULATOR,
} from "util/const"

// Styling
import "./App.css"
import "./constants.css"

// Component
export const App = () => {

  // const loader = useRef(null)
  // useEffect(() => {
  //   setTimeout(() => {
  //     loader.current.classList.add("loader-wrapper--hide")
  //   }, 3000)
  // }, [])

  return (

    <ProvideGlobals globals={{
      theme: "light"
    }}>

      {/* TODO: Make timer configurable & convert into a component */}
      {/* <div ref={loader} className={`loader-wrapper`}>
        <div className="loader">
          <div className="loader-bgs">
            <div className="loader-bg1"></div>
            <div className="loader-bg2"></div>
          </div>
          <div className="loader-fgs">
            <div className="loader-fg1"></div>
            <div className="loader-fg2"></div>
            <div className="loader-fg3"></div>
            <div className="loader-fg4"></div>
          </div>
        </div>
      </div> */}

      <BrowserRouter basename={ROUTE_PREFIX}>
        {ENABLE_CATALOG
          ? (
            <Routes>
              <Route path={`/${ROUTE_CATALOG}`} element={<Catalog />} />
              <Route path="*" element={<Navigate to={`/${ROUTE_CATALOG}`} replace />} />
            </Routes>
          )
          : (
            <Routes>
              {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_HOME) && (
                <Route path={`/${ROUTE_HOME}`} element={<Home />} />
              )}

              <Route path={`/${ROUTE_CAMPAIGNS}`} element={<Campaigns />} />
              <Route path={`/${ROUTE_CAMPAIGNS_01}`} element={<Audiences />} />
              <Route path={`/${ROUTE_CAMPAIGNS_02}`} element={<Locations />} />
              <Route path={`/${ROUTE_CAMPAIGNS_03}`} element={<Category />} />
              {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CAMPAIGNS_INTEREST_CATEGORIES) && (
                <Route path={`/${ROUTE_CAMPAIGNS_04}`} element={<IAB />} />
              )}
              <Route path={`/${ROUTE_CAMPAIGNS_05}`} element={<Timing />} />
              {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CAMPAIGNS_DEVICE_PERFORMANCE) && (
                <Route path={`/${ROUTE_CAMPAIGNS_06}`} element={<Devices />} />
              )}

              {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_HELP) && (
                <Route path={`/${ROUTE_HELP}`} element={<Help />} />
              )}

              {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_SETTINGS) && (
                <Route path={`/${ROUTE_SETTINGS}`} element={<Settings />} />
              )}

              {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CALCULATOR) && (
                <Route path={`/${ROUTE_CALCULATOR}`} element={<Calculator />} />
              )}

              <Route path="*" element={<Navigate to={`/${ROUTE_CAMPAIGNS}`} replace />} />
            </Routes>
          )}
      </BrowserRouter>
    </ProvideGlobals>
  )
}
export default App