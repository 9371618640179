import {
  useEffect,
  useMemo,
  useRef
} from "react"

// Elements
import {
  CheckboxGroup,
  Insert,
  Tick
} from "elements"

import "./TableSort.css"

export const TableSort = ({data, undim, stick/* TODO (1/3): Detect from data (e.g. Insert components) instead (if we can sort its contents we can read them) */=false, sort=true, slice=[]}) => {
  const tHead = useRef()
  const tBody = useRef()
  const lastSelection = useRef()

  // const lastSelection.current = useRef(null)
  // const [_, reRender] = useState(false)
  useEffect(() => {

    // TODO: 
    // - Build table tree (from tabular arrays)
    // - Fix the FF "jumping [into a too-short last row]" behavior after adding this class
    if (!slice.length) tHead.current.parentElement.classList.add("tablesort-footless")

    // Set default [onload] sorting criteria (first column ascending instead of meaningless data FIFO order) if none was previously set
    if (lastSelection.current === undefined) sortData(0)

  }, [data])

  const counters = useMemo(/* Compute once (unless 'data' changes) */() => {
    if (data) return new Array(data?.[0].length).fill(0)
  }, [data])

  const sortData = (index) => {

    // Reset previous column sorting selection
    const headCells = tHead.current.firstElementChild.children
    let sameColumn = lastSelection.current === index
    lastSelection.current = index
    if (!sameColumn) {
      for (const th of headCells) {
        th.className = ""
      }
    }

    // Abort if no column was really selected (e.g. first [flush] load)
    if (isNaN(index)) return// -> ALT: if (index === null) return

    // Normalize cell values (to be able to sort them)
    const getNumber = (string) => {

      // TODO (2/3): Detect from data (e.g. Insert components) instead (if we can sort its contents we can read them)
      return ["-", "Total", "All"].includes(string) ? -Infinity : (string).replace(/[^0-9-]/g, "")
    }
 
    // Extract columns from the existing table (it doesn't matter what [DOM] table builder was used on first load)
    let value
    let count = 0
    const rows = []

    // TODO (3/3): Detect from data (e.g. Insert components) instead (if we can sort its contents we can read them)
    // let sticky = stick //true
    for (const tr of tBody.current.children) {

      // Legacy (1/2):if (!sticky) {// -> Meaning 'second time/row & beyond' [if 'sticky = false' therefore '!sticky' after 1st round]
      if (!tr.classList.contains("tablesort__body-row__all")) {
        value = tr.children[index].textContent
        count = getNumber(value) ? count + 1 : count
        rows.push([value, tr])
      }
      //Legacy (2/2): sticky = false
    }

    // Sort (and style the header accordingly)
    rows.sort(count < (rows.length * .5/* Sweet spot */) 
      ? /* Lexicographic order */undefined 
      : /* Numeric order */ (a, b) => {
        return getNumber(a[0]) - getNumber(b[0])
      }
    )

    const order = headCells[index].classList
    if (order.contains("tablesort__head-cell--ascend")) {
      order.remove("tablesort__head-cell--ascend")
      order.add("tablesort__head-cell--descend")
      rows.reverse()
    } else {
      order.remove("tablesort__head-cell--descend")
      order.add("tablesort__head-cell--ascend")
    }

    // Refill the table body (the old element is first removed if already in the tree)
    rows.map((tr) => {  
      tBody.current.appendChild(tr.pop())
    })
  }

  return (
    <table className={`tablesort ${stick ? "tablesort--stick" : ""}`}>
      <thead ref={tHead}>
        <tr className={`tablesort__head-row ${sort ? "tablesort__head-row--sort" : ""}`}>
          {data[0].map((entry, index) => 
            <td 
              key={index} 
              onClick={() => sort ? sortData(index) : null}
              >
              <div className={`${undim && [4, 5, 6].includes(index) && index !== undim ? "tablesort__head-cell__lining--dim" : ""} tablesort__head-cell__lining`}>{/* -> TODO: This wouldn't be necessary if the table was already a modern div-powered one (to be converted) */}
                <span className="tablesort__head-cell-txt">{[
                  entry,
                  "Avg heartbeat",
                  "Reach",
                  "% - Reach",
                  "% - High",
                  "% - Medium",
                  "% - Low",
                  "Avg session len",
                  "% - Viewability"
                ][index]}&nbsp;</span>
                <span className="tablesort__head-cell-l2h">↓&nbsp;</span>{/* -> The [sorting] arrow means 'reading order' */}
                <span className="tablesort__head-cell-h2l">↑&nbsp;</span>
              </div>
              <div className={`${undim && [4, 5, 6].includes(index) && index !== undim ? "tablesort__head-cell__lining--dim" : ""} tablesort__head-cell__lining`}>{/* -> TODO: This wouldn't be necessary if the table was already a modern div-powered one (to be converted) */}
                {/* {Array.isArray(entry) ? entry[entry.length - 1] : ""} */}

                {index ? <Tick value={entry + "%"}/> : ""}
              </div>
            </td>
          )}
        </tr>
      </thead>
      <tbody ref={tBody}>
        {
          // Blueprint mode (v24/01 - Adverty) -> TODO: 'Blueprint/Template/mode' support (e.g. "template: 'adverty24_01')
          data.reverse().map((tr_item, tr_index) => { 

            // Sum column values up for every column
            counters.map((_, index) => counters[index] += tr_item[index])

            // Build rich rows
            const total_rows = data.length - 1

            const stuck_row = stick && (tr_index === total_rows)
            const avg = (index) => {
              if (stuck_row) {
                return index ? Math.floor(counters[index] / total_rows) : "All " + tr_item[0].toLowerCase()
              } else {
                return tr_item[index]
              }
            }
            const rich_rows = [
              <CheckboxGroup label={avg(0)}/>,
              <Insert what={avg(1)}/>, 
              
              // TODO: To be converted into util (same as w/ "true [epsilon-fix] round w/ decimals support")
              Math.abs(avg(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),

              avg(3), 
              <Tick value={avg(4)} tag={1}/>, 
              <Tick value={avg(5)} tag={2}/>, 
              <Tick value={avg(6)} tag={3}/>, 
              avg(7), 
              avg(8)
            ]
            return <tr 

            // Template as a function (with "stuck_row" flags)
            // TODO: Harmonize odd-painting rows class (ugly hack for the time being to make it compatible w/ hidding rows)
            className={`${tr_index > (slice?.[0] - 1) && tr_index < slice?.[1] ? "tablesort__body-row--hide" : ""} ${!slice.length || tr_index % 2 ? "" : "tablesort__body-row--odd"} ${slice.length ? "" : "tablesort__body-row--odd-auto"} tablesort__body-row ${stuck_row ? "tablesort__body-row__all" : ""}`}
            
            key={tr_index}
            >{rich_rows.map((td_item, td_index) => {
            
              return <td 
                className={`${undim && [4, 5, 6].includes(td_index) && td_index !== undim ? "tablesort__body-cell--dim" : ""}`}
                key={td_index}>{td_item}</td>
            })
          }</tr>

       })

      }
      </tbody>
      <tfoot>
        <tr className="tablesort__foot-row">
          <td colSpan={data[0].length}><div>{slice[0] + 1}-{slice[1]}</div>of {data.length - 1}</td>
        </tr>
      </tfoot>
    </table>
  )
}