import {
  useEffect,
  useRef
} from "react"

// Contexts
import { 
  useGlobals
} from "contexts"

// Elements
import { 
  Insert,
  Tick/* TODO: Improve support for clock-like times (preferred b/c of uniqueness [this CG component will break otherwise] & consistency) OR give full support for ticks */
} from "elements"

// Catalog-wide styling
import "./CheckboxGroup.css" 

// All-inclusive keywords (compute once)
const selectAllId = ["total", "all", "un"]
for (let i = 0; i < selectAllId.length; i++) {
  selectAllId[i] = "checkboxgroup-" + selectAllId[i]
}

export const CheckboxGroup = ({label, checked=true}) => {
  label = isNaN(label) ? label : "" + label// -> Numbers are converted to strings (to be able to use them as keys)

  // TODO: Support icon groups if needed (odd use case anyway)
  const isString = typeof label === "string"

  const globals = useGlobals() 
  const id = "checkboxgroup-" + (isString 
    ? (
      label.slice(0, 4).toLowerCase() === "all " 
        ? "all" 
        : label?.replaceAll(/\s/g, "_").toLowerCase()
      ) 
    : null)
  // const selectAllId = "checkboxgroup-total"// -> Legacy

  let picks = [] 
  const selectSomeClass = "checkboxgroup__pick-some"

  const input = useRef()
  useEffect(() => {

      // Default checkbox state (recall from previous choices if stored in globals)
      const buffer = globals.get("checkboxed")
      const labelId = "checkboxgroup-" + label?.replaceAll(" ", "_")?.toLowerCase()
      const isAll = buffer !== undefined && selectAllId.includes(labelId.split("_")[0])
      input.current.checked = !buffer || buffer?.includes(labelId) || isAll
  
      // TODO: We need [sortable so chart x-values can be sortened too] null/unchecked remembered values too (otherwise we can't know beforehand if 'All' should be full or partial)
      // > This is why 'All' gets 'selectSomeClass'd after returning to the section even if all checkboxes are checked  
      isAll && input.current.classList.add(selectSomeClass)

  }, [label])

  return <div className="checkboxgroup" onClick={(e) => {
    e.stopPropagation()

    const current = e.target // ALT: 'e.currentTarget.firstElementChild' or 'input' from 'const input = useRef()'
    const currentParent = e.currentTarget

    if (current.name === "_" && isString) {

      // Auto-detect group
      let items
      let depth = 3 // -> Ok b/c up to ~10000 calls w/o any noticeable lag
      while (depth--) {
        if (depth === 3) items = currentParent.parentElement?.querySelectorAll("input[name='_']")
        if (depth === 2) items = currentParent.parentElement?.parentElement?.querySelectorAll("input[name='_']")
        if (depth === 1) items = currentParent.parentElement?.parentElement?.parentElement?.querySelectorAll("input[name='_']")
        if (items.length > 1) break
      }

      // Every time a new checkbox is clicked we need to update the picks array
      let total
      for (const item of items) {
        if (!selectAllId.includes(item.id)) {
          item.checked && picks.push(item.id)// Register the ones already checked
        } else {
          total = item
        }
      }

      // A - The 'All' checkbox is checked/unchecked 
      if (selectAllId.includes(current.id)) {// -> Only 'All'
        picks = []
        const allToBeTicked = current.checked
        if (allToBeTicked) {
          total.classList.remove(selectSomeClass) 
        }
        for (const item of items) {
          item.checked = allToBeTicked
          if (!selectAllId.includes(item.id)) {
            item.checked && picks.push(item.id)// Buffer checkbox (all at once)
          }
        }
      } 
      // B - Some non-'All' checkbox is checked/unchecked
      else if (total) {// -> All except 'All'
        if (picks.length) {
          total.checked = true
          total.classList[picks.length === (items.length - 1) ? "remove" : "add"](selectSomeClass)
        } else {
          total.classList.remove(selectSomeClass)
          total.checked = false
        }
      }

      // Share the picks w/ other components and [globally] remember that selection at the same time (w/o using sessionStorage)
      globals.set("checkboxed", picks, true/* so constrained to the current [section] pathname */)
    }
  }
  }>
    <input ref={input} className="checkboxgroup__pick" value={label} type="checkbox" name="_" id={id} /*disabled={index === 2}*//>
    <label className="checkboxgroup__text" htmlFor={id}>{isNaN(label) ? <Insert what={label}/> : <Tick value={label + ":00"} tag="1"/>}</label>
  </div>
}