import {
  useRef,
  useMemo
} from "react"

// Contexts
import { 
  useGlobals
} from "contexts"

import {
  TitleCard,
} from "elements"

import "./ChartBars.css"

// Credit: https://stackoverflow.com/questions/6117814
// TODO: Learn & distill
const getWeek = (date) => {
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7))
  const dayOne = new Date(Date.UTC(date.getUTCFullYear(), 0, 1))
  return Math.ceil(( ( (date - dayOne) / 86400000) + 1) / 7)
}
const getDay = (date) => {
  return Math.floor((date - new Date(date.getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24))
}

export const ChartBars = ({data, text={}, span="d"}) => {// TODO: Series as prop
  const seriesAverage = Math.round(data?.reduce((acc, cur) => acc + cur, 0) / data?.length) || 0

  const globals = useGlobals()
  const timeframe = useRef(globals?.context?.timeframe || "w")

  // TODO: Merge new logic w/ xLabels below for any kind of timeframe support (to be implemented by buttons/filters)
  // Convert daily bars into weekly/monthly ones
  const series = useMemo(/* Compute once (unless 'data' changes) */() => {
    const barsPerBatch = {
      "d": 1,
      "w": 7,
      "m": 30
    }[span]
    let accumulator = 0
    const averages = []
    data.map((_, index) => {
      accumulator += _
      if (!((index + 1) % barsPerBatch)) {
        averages.push(Math.floor(accumulator / barsPerBatch))
        accumulator = 0
      } 
    })
    return averages


  }, [data])

  // TODO: 
  // - Compute once
  // - Percentual height instead (so if we change the grid's cells height, everything adapts accordingly)
  // ---------------------------------------------------
  const boxHeight = 320 * 0.7/*inner-box-height-factor*/
  // ---------------------------------------------------
  const barWidth = 7.25

  const boxWidth = barWidth * series?.length || 0
  const rangeMaximum = 99// -> Max bar value (top of the scale range)
  const barHeightFactor = ((boxHeight - /* >> */10/* This is a patch for demo purposes (if filtering system approved then refactor the right way) << */) / rangeMaximum)

  // const days = Math.floor(365 / 12)

  // Dynamic time series labeling
  /* TODO: 
     - Yearly vertical separators
     - Alternating gaps/bars support (by injecting zero values [instead of hidding bars/data])
  */
  // OLD: const xLabels = new Array(Math.round(data.length / 4.34524/*weeks-per-month*/)).fill("Jan")
 
  // === Shared among all timeframes
  // Compute starting date (by substracting the number of daily datapoints from today's date)
  const dateNow = new Date()
  const date1st = new Date((new Date()).setDate(dateNow.getDate() - series?.length || 0 /* - TODO: add missing datapoints/days from Today */))// - (/*TEST (pretend days are weeks):*/series.length * 4.34524/*TODO: No need unless we are showing/allowing-selecting week numbers instead of months [b/c daily bars are fixed by design]*/)))
  
  // Compute current year's day & week number
  const thisDay = Math.floor((dateNow - new Date(dateNow.getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24))
  const thisWeek = Math.ceil(thisDay / 7)// -> Same as getWeek(dateNow) but faster

  // TODO: 
  // - Have a prop ready for this block of choices below
  // - Group/avg daily datapoints into weekly/monthly bars (so we can use those bars as the base unit for the timeframes below)
 
  // === Filter by week
  // Get week index of starting date (e.g. series start on December or #12)
  const weekIndex1st = getWeek(date1st)

  // Compute number of weeks between starting date and today
  const diffWeeks = ((dateNow.getYear() - date1st.getYear()) * 52/* Weeks */) + thisWeek
  
  // // === Filter by month
  // Get month index of starting date (e.g. series start on December or #12)
  const monthIndex1st = date1st.getMonth()

  // Compute number of months between starting date and today
  const diffMonths = ((dateNow.getYear() - date1st.getYear()) * 12/* Months */) + (dateNow.getMonth() - monthIndex1st) + 1/*Current*/ // -> Days [and therefore weekdays] already available from raw daily data

  // === Filter by year (each bar/datapoint is supposed to be a month)
  // Get year index of starting date
  const yearIndex1st = date1st.getFullYear()

  // Compute number of years between starting date and today
  const diffYears = dateNow.getFullYear() - yearIndex1st 

  // === Shared
  // Generate a series of labels from now until the end of the series
  const xLabels = {
    "y": [...Array(diffYears)].map((_, idx) => (date1st.getFullYear() + idx).toString()),
    "m": [...Array(diffMonths)].map((_, idx) => ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][(idx + monthIndex1st) % 12]),
    "w": [...Array(diffWeeks)].map((_, idx) => /* TODO: Generate dynamically (as already done w/ 'y') */ ["W01", "W02", "W03", "W04", "W05", "W06", "W07", "W08", "W09", "W10", "W11", "W12", "W13", "W14", "W15", "W16", "W17", "W18", "W19", "W20", "W21", "W22", "W23", "W24", "W25", "W26", "W27", "W28", "W29", "W30", "W31", "W32", "W33", "W34", "W35", "W36", "W37", "W38", "W39", "W40", "W41", "W42", "W43", "W44", "W45", "W46", "W47", "W48", "W49", "W50", "W51", "W52"][(idx + weekIndex1st) % 52])

  }[timeframe.current/* TODO: From prop 'span' prop instead */]
  
  return (
    <main className="chartbars-metawrapper" style={{
      "--average": ((boxHeight * seriesAverage) * 0.01) + "px"
    }}>
      <TitleCard 
        title={text.title || ""} 
        subtitle={text.subtitle || ""}
      />

      <div className={`chartbars-buttons text-xs-b`}>
        <div className="chartbars-buttons__switch">
          <span className={`chartbars-buttons__switch__m ${ timeframe.current === "w" ? "chartbars-buttons__switch__w--selected" : ""}`} onClick={() => globals.set("timeframe", timeframe.current = "w")}>By week</span>
          <span className={`chartbars-buttons__switch__w ${ timeframe.current === "m" ? "chartbars-buttons__switch__m--selected" : ""}`} onClick={() => globals.set("timeframe", timeframe.current = "m")}>By month</span>
        </div>
      </div>

      <div className="chartbars-wrapper">

        <svg className="chartbars" height={boxHeight} viewBox={`0 0 ${barWidth * series?.length || 0} ${boxHeight}`} > {/* 684 342  preserveAspectRatio="xMinYmid meet"     preserveAspectRatio="xMinYMid meet"  */}
          <g transform={`scale(1, -1) translate(0, ${boxHeight * -1})`} onMouseOver={(event) => {
            const visibleBar = event.target.parentElement?.lastElementChild
            const targetH = visibleBar?.height?.baseVal.value
            const targetX = visibleBar?.x?.baseVal.value
            if (targetH && targetX) {

              // Dot
              const hoverDot = event.currentTarget.lastElementChild
              hoverDot.setAttribute("cy", targetH)
              hoverDot.setAttribute("cx", targetX + ((barWidth - 1) * 0.5))

              // Bar
              const hoverBar = hoverDot.previousElementSibling
              hoverBar.setAttribute("height", targetH)
              hoverBar.setAttribute("x", targetX)
            }
          }}>
          {series?.length && series?.map((item, index) => {
              return (
              <g key={index} >
                <rect width={barWidth + 1} x={index * barWidth} y="0" height="100%" className="chartbars__bar chartbars__bar-filler"/>
                <rect width={barWidth - 1} x={index * barWidth} y="0" height={item * barHeightFactor} className="chartbars__bar">
                  <animate attributeName="height" from="0" to={(item * barHeightFactor)} dur="1s" /* keySplines="" keyTimes="0" */ />
                </rect>
              </g>
          )})}
          <rect y="0" className="chartbars__mask-bg"></rect>
          <rect width={barWidth - 1} y="0" className="chartbars__bar chartbars__bar--lit"></rect> 
          <circle cx="-100" cy="-100" r={barWidth + 2} className="chartbars__dot"/>


        </g>
      </svg>

      <div className="chartbars__dash"></div>
      <div className="chartbars__dash-back"></div>
      
      <div className="chartbars__timeframe-labels text-xs" style={{"width": boxWidth + "px"}}>
        {xLabels.map((item, index) => {
          return (
            <span key={index}>{item}</span>
        )})}
      </div>
      
    </div> 

    <div className="chartbars__mask-fg"></div>
    <div className="chartbars__arrow"></div>
    
    <div className="chartbars__disc"></div>
    
    <div className="chartbars__dash-label text-xs">My all-time average</div>
    <div className="chartbars__dash-number text-xs"><b>{seriesAverage}</b></div>
  
  </main>
)}