// import {
//   useState
// } from "react"

// Containers
import { 
    Layout,
    Grid
} from "containers"

// Components
import { 
  Topbar,
  Wheel,
  ChartBars,
  TableCompare
} from "components"

// Elements
import {
  Tick,
  Insert
} from "elements"

// Home-wide styling
import "./Campaigns.css"

// Campaigns assembly
export const Campaigns = () => {

  // const [expanded, setExpanded] = useState(false)



  
  return (
    <Layout header={{
      "title": "Ads Overview",
      "search": ""
    }}>

      <div className="_tempDummyHeader">Overview</div>
     
      <Grid
        content={[
          <Wheel 
            text={{
              title: "Heartbeat",
              subtitle: ""
            }}
            data={[
              [37, -1],
              // [35, 0]
            ]}
            mode={1}
          />,

          <ChartBars 
            text={{
              title: "History",
              // subtitle: "Your audience engagement over time"
            }}
            data={
              Array(365 * 2/* Years of dummy data */).fill(0).map(() => Math.floor(Math.random() * (42/*max*/ - 32/*min*/) + 32))/* -> Pattern repeater for demo-ing purposes */
              // Array(365 * 2/* Years of dummy data */).fill(0).map(() => Math.floor(Math.random() * 100))/* -> Pattern repeater for demo-ing purposes */
            }
          />
        ]}
        template={`
            0 1 1
            `}
        cellSize={{
          w: 255,
          h: 320
        }}
      />

      <Grid
        content={[
          <Topbar 
            data={[
              {
                "name": "Reach",
                "data": [360823, 360823],
                "unit": ""
              }
            ]}
          />,
          <Topbar 
            data={[
              {
                "name": "Total sessions",
                "data": [838381, 838381],
                "unit": ""
              }
            ]}
          />,
          <Topbar 
            data={[
              {
                "name": "Avg session length",
                "data": ["24.68s", "24.68s"],
                "unit": ""
              }
            ]}
          />,
          <Topbar 
            data={[
              {
                "name": "Viewability percentage",
                "data": [42.16, 42.16],
                "unit": "%"
              }
            ]}
          />
        ]}
        template={`
            0 1 2 3
            `}
        cellSize={{
          w: 146,
          h: 80
        }}
      />

      <Grid
        content={[
          <TableCompare 
          data={[

            // Preview
            [["f20c", "m25c", "m35c"], ["f60c", "m30c", "f25c"], ["m45c", "f40c", "m80c"]],
            [["17%", 13], ["61%", 7], ["22%", -7]],
            [["$1.6", 13], ["$2.1", -7], ["$2.6", 7]],
            [["52.3%", 13], ["61.1%", 7], ["72.4%", 7]],

            // Key metrics
            [10, 37, 60],
            [["17%", 7], ["61%", 13], ["22%", -7]],
            [["1784", 7], ["65126", 7], ["30322", 13]],
            [["$1.6", 7], ["$2.1", -7], ["$2.6", 13]],
            [["52.3%", 7], ["61.1%", 13], ["72.4%", 13]],
            
            // Ads top performers
            ["Claire McCormick", "Julia Jones", "Franc Wilde"],
            ["Berlin, Germany", "Cairo, Egypt", "Riyadh, Saudi Arabia"],
            ["Travel", "Science", "Action"],
            ["10:00 - 12:00", "15:00 - 18:00", "17:00 - 21:00"],
            ["iPhone X", "Samsung S3", "iPhone 13"],

            // Subscriptions top performers
            ["Claire McCormick", "Julia Jones", "Franc Wilde"],
            ["Kuwait, Kuwait", "Los Angeles, United States", "Riyadh, Saudi Arabia"],
            ["Travel", "Science", "Action"],
            ["10:00 - 12:00", "15:00 - 18:00", "17:00 - 21:00"],
            ["iPhone X", "Samsung S3", "iPhone 13"],

            // Content top performers
            ["Claire McCormick", "Julia Jones", "Franc Wilde"],
            ["Berlin, Germany", "Casablanca, Morocco", "London, United Kingdom"],
            ["Travel", "Science", "Action"],
            ["10:00 - 12:00", "15:00 - 18:00", "17:00 - 21:00"],
            ["iPhone X", "Samsung S3", "iPhone 13"],
          ]}
          
          // -------------------
          poly={false}
          // -------------------
          />
        ]}
        template={`
            0
            `}
        cellSize={{
          w: 800,
          h: 1//Ignored -> Legacy: 'expanded ? 800 : 320'
        }}

        flat={true}// ???
        
      />
    </Layout>
)}