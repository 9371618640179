import {
  useState
} from "react"

import "./PopoverHelp.css"

// Icons
import { ReactComponent as IconHelp } from "assets/icons/small/help_question_mark.svg"

export const PopoverHelp = ({data}) => {
  const [popUp, setPopUp] = useState(false)
  return (
      <>
          {/* TODO: Wrapper should be div styled as a single [tooltip + icon] block */}
          <div className="popoverhelp-icon text-xs" >
              <IconHelp className="popoverhelp-icon__img" onMouseEnter={() => setPopUp(true)} onMouseLeave={() => setPopUp(false)}/>
              {data.text}
          </div>

          <div className={`popoverhelp ${popUp && "popoverhelp--show"} text-xs`}>
              <b>{data.title}</b>
              <span>{data.details}</span>
          </div>
      </>
  )
}