// Contexts
import {
  useGlobals
} from "contexts"

// Containers
import { 
  Grid,
  Layout
} from "containers"

// Components
import {
  TableSort,
  ChartMap
} from "components"

// Locations-wide styling
import "./Locations.css"

// Locations assembly
export const Locations = () => {

  // --- Shared ---
  const globals = useGlobals() 

  // Unpack engagement grouping settings
  const group = globals?.context?.selected?.[0] || "_"
  
  // const picks = globals.get("checkboxed") || ["US", "MX", "IN", "ID", "BR", "FR", "DE", "GB", "IT", "CA", "NL", "CL", "AR", "AU", "BE", "AT", "CH", "AE", "AZ", "CO", "CR"]
  const picks = globals.get("checkboxed") || ["AE", "AZ", "BH", "CY", "EG", "IL", "IQ", "IR", "JO", "KW", "LB", "OM", "PS", "QA", "SA", "SY", "TR"]

  // Dummy data loader (-> [Reach pct, [H, M, L]])
  let obj_all = {
    "UNDEFINEDLOCATION": [5.26, [7.64, 5.06, 3.47]],

    // Middle East
    "AE": [12, [25, 39, 37]],
    "AZ": [23, [25, 39, 37]],
    "BH": [41, [25, 39, 37]],
    "CY": [13, [25, 39, 37]],
    "EG": [72, [25, 39, 37]],
    "IL": [91, [25, 39, 37]],
    "IQ": [43, [25, 39, 37]],
    "IR": [12, [25, 39, 37]],
    "JO": [88, [25, 39, 37]],
    "KW": [42, [25, 39, 37]],
    "LB": [18, [25, 39, 37]],
    "OM": [42, [25, 39, 37]],
    "PS": [75, [25, 39, 37]],
    "QA": [32, [25, 39, 37]],
    "SA": [11, [25, 39, 37]],
    "SY": [9, [25, 39, 37]],
    "TR": [62, [25, 39, 37]],

    // World
    "US": [22.41, [17.71, 24.03, 22.23]],
    "MX": [8.92, [12.49, 8.91, 5.37]],
    "IN": [5.79, [8.32, 5.38, 4.48]],
    "DE": [5.00, [2.45, 4.72, 8.40]],
    "FR": [4.79, [2.54, 4.69, 7.31]],
    "BR": [4.76, [7.08, 4.74, 2.51]],
    "GB": [4.63, [2.84, 4.44, 7.02]],
    "IT": [3.09, [1.49, 3.31, 4.01]],
    "ID": [2.68, [5.42, 2.33, 0.99]],
    "CA": [2.59, [1.89, 2.58, 3.32]],
    "TR": [2.21, [1.18, 2.32, 2.92]],
    "CL": [2.17, [2.42, 2.31, 1.50]],
    "ES": [1.49, [0.65, 1.52, 2.23]],
    "PL": [1.40, [1.12, 1.42, 1.59]],
    "NL": [1.35, [0.49, 1.01, 3.21]],
    "AR": [1.26, [1.83, 1.30, 0.57]],
    "KZ": [1.22, [1.45, 1.27, 0.85]],
    "TH": [1.17, [2.00, 1.06, 0.68]],
    "SA": [1.10, [1.27, 1.09, 0.94]]
  }

  let obj_sel = {}
  for (let i = 0; i < picks?.length; i++) {
    let code = picks[i].replace("checkboxgroup-", "").toUpperCase()
    obj_sel[code] = obj_all[code]
  }
  // --- Shared ---
  
  return (
    <Layout header={{
      "title": "Locations",
      "search": ""
    }}>

      <div className="_tempDummyHeader">Locations</div>

      <Grid
        content={[
          <ChartMap 
            data={obj_sel}
            mode={0}
            />,
        ]}
        template={`
          0
        `}
        cellSize={{
          w: 800, 
          h: 500
        }}
      />

      <Grid
        content={[
          <TableSort 

            data={{
              "_": [
                // --- Head
                ["Locations", 12, 12, 12, 12, 12, 12, 12, 122],
                
                // --- Body
                ["AE",	40,	4993, 7.44, 8.56, 7.46, 5.90, 10.4, 40.82],
                ["AZ", 34, 2733, 4.07, 2.66, 4.00, 6.16, 12.7, 42.52],
                ["BH", 36, 209, 0.31, 0.30, 0.31, 0.35, 9.06, 35.75],
                ["CY", 42, 139, 0.21, 0.31, 0.19, 0.14, 9.68, 59.64],
                ["EG", 31, 47, 0.07, 0.04, 0.07, 0.11, 12.15, 33.76],
                ["IL", 28, 1052, 1.57, 1.07, 1.43, 2.71, 6.5, 11.99],
                ["IQ", 27, 312, 0.46, 0.18, 0.41, 1.04, 12.78, 8.22],
                ["IR", 30, 3959, 5.90, 3.48, 5.28, 11.29, 13.84, 35.14],
                ["JO", 31, 1015, 1.51, 0.54, 1.51, 2.78, 11.82, 33.16],
                ["KW", 28, 3766, 5.61, 5.90, 5.78, 4.62, 10.24, 38.36],
                ["LB", 39, 55, 0.08, 0.07, 0.09, 0.07, 16.07, 48.02],
                ["OM", 38, 815, 1.21, 1.02, 1.27, 1.25, 10.99, 46.85],
                ["PS", 37, 168, 0.25, 0.20, 0.23, 0.41, 13.09, 27.85],
                ["QA", 33, 1430, 2.13, 2.35, 2.15, 1.76, 10.11, 36.72],
                ["SA", 34, 15166, 22.59, 17.73, 22.03, 30.94, 10.52, 31.03],
                ["SY", 34, 738, 1.10, 0.82, 1.07, 1.58, 18.18, 34.13],
                ["TR", 41, 30534, 45.48, 54.76, 46.74, 28.88, 15.14, 37.85]
              ],
              "high": [
                // --- Head
                ["Locations", 12, 12, 12, 12, 12, 12, 12, 122],
                
                // --- Body
                ["AE",	40,	4993, 7.44, 8.56, 7.46, 5.90, 10.4, 40.82],
                ["AZ", 34, 2733, 4.07, 2.66, 4.00, 6.16, 12.7, 42.52],
                ["BH", 36, 209, 0.31, 0.30, 0.31, 0.35, 9.06, 35.75],
                ["CY", 42, 139, 0.21, 0.31, 0.19, 0.14, 9.68, 59.64],
                ["EG", 31, 47, 0.07, 0.04, 0.07, 0.11, 12.15, 33.76],
                ["IL", 28, 1052, 1.57, 1.07, 1.43, 2.71, 6.5, 11.99],
                ["IQ", 27, 312, 0.46, 0.18, 0.41, 1.04, 12.78, 8.22],
                ["IR", 30, 3959, 5.90, 3.48, 5.28, 11.29, 13.84, 35.14],
                ["JO", 31, 1015, 1.51, 0.54, 1.51, 2.78, 11.82, 33.16],
                ["KW", 28, 3766, 5.61, 5.90, 5.78, 4.62, 10.24, 38.36],
                ["LB", 39, 55, 0.08, 0.07, 0.09, 0.07, 16.07, 48.02],
                ["OM", 38, 815, 1.21, 1.02, 1.27, 1.25, 10.99, 46.85],
                ["PS", 37, 168, 0.25, 0.20, 0.23, 0.41, 13.09, 27.85],
                ["QA", 33, 1430, 2.13, 2.35, 2.15, 1.76, 10.11, 36.72],
                ["SA", 34, 15166, 22.59, 17.73, 22.03, 30.94, 10.52, 31.03],
                ["SY", 34, 738, 1.10, 0.82, 1.07, 1.58, 18.18, 34.13],
                ["TR", 41, 30534, 45.48, 54.76, 46.74, 28.88, 15.14, 37.85]
              ],
              "medium": [
                // --- Head
                ["Locations", 12, 12, 12, 12, 12, 12, 12, 122],
                
                // --- Body
                ["AE",	40,	4993, 7.44, 8.56, 7.46, 5.90, 10.4, 40.82],
                ["AZ", 34, 2733, 4.07, 2.66, 4.00, 6.16, 12.7, 42.52],
                ["BH", 36, 209, 0.31, 0.30, 0.31, 0.35, 9.06, 35.75],
                ["CY", 42, 139, 0.21, 0.31, 0.19, 0.14, 9.68, 59.64],
                ["EG", 31, 47, 0.07, 0.04, 0.07, 0.11, 12.15, 33.76],
                ["IL", 28, 1052, 1.57, 1.07, 1.43, 2.71, 6.5, 11.99],
                ["IQ", 27, 312, 0.46, 0.18, 0.41, 1.04, 12.78, 8.22],
                ["IR", 30, 3959, 5.90, 3.48, 5.28, 11.29, 13.84, 35.14],
                ["JO", 31, 1015, 1.51, 0.54, 1.51, 2.78, 11.82, 33.16],
                ["KW", 28, 3766, 5.61, 5.90, 5.78, 4.62, 10.24, 38.36],
                ["LB", 39, 55, 0.08, 0.07, 0.09, 0.07, 16.07, 48.02],
                ["OM", 38, 815, 1.21, 1.02, 1.27, 1.25, 10.99, 46.85],
                ["PS", 37, 168, 0.25, 0.20, 0.23, 0.41, 13.09, 27.85],
                ["QA", 33, 1430, 2.13, 2.35, 2.15, 1.76, 10.11, 36.72],
                ["SA", 34, 15166, 22.59, 17.73, 22.03, 30.94, 10.52, 31.03],
                ["SY", 34, 738, 1.10, 0.82, 1.07, 1.58, 18.18, 34.13],
                ["TR", 41, 30534, 45.48, 54.76, 46.74, 28.88, 15.14, 37.85]
              ],
              "low": [
                // --- Head
                ["Locations", 12, 12, 12, 12, 12, 12, 12, 122],
                
                // --- Body
                ["AE",	40,	4993, 7.44, 8.56, 7.46, 5.90, 10.4, 40.82],
                ["AZ", 34, 2733, 4.07, 2.66, 4.00, 6.16, 12.7, 42.52],
                ["BH", 36, 209, 0.31, 0.30, 0.31, 0.35, 9.06, 35.75],
                ["CY", 42, 139, 0.21, 0.31, 0.19, 0.14, 9.68, 59.64],
                ["EG", 31, 47, 0.07, 0.04, 0.07, 0.11, 12.15, 33.76],
                ["IL", 28, 1052, 1.57, 1.07, 1.43, 2.71, 6.5, 11.99],
                ["IQ", 27, 312, 0.46, 0.18, 0.41, 1.04, 12.78, 8.22],
                ["IR", 30, 3959, 5.90, 3.48, 5.28, 11.29, 13.84, 35.14],
                ["JO", 31, 1015, 1.51, 0.54, 1.51, 2.78, 11.82, 33.16],
                ["KW", 28, 3766, 5.61, 5.90, 5.78, 4.62, 10.24, 38.36],
                ["LB", 39, 55, 0.08, 0.07, 0.09, 0.07, 16.07, 48.02],
                ["OM", 38, 815, 1.21, 1.02, 1.27, 1.25, 10.99, 46.85],
                ["PS", 37, 168, 0.25, 0.20, 0.23, 0.41, 13.09, 27.85],
                ["QA", 33, 1430, 2.13, 2.35, 2.15, 1.76, 10.11, 36.72],
                ["SA", 34, 15166, 22.59, 17.73, 22.03, 30.94, 10.52, 31.03],
                ["SY", 34, 738, 1.10, 0.82, 1.07, 1.58, 18.18, 34.13],
                ["TR", 41, 30534, 45.48, 54.76, 46.74, 28.88, 15.14, 37.85]
              ]
            }[group]}
            stick={!!group}
            sort={!!group}
            undim={{"high": 4, "medium": 5, "low": 6}[group]}
          />
          ]}
        template={`
            0
            `}
        cellSize={{
          w: 800, 
          h: 950 /* TODO: Number of rows from dataset length [len * rows] instead (vs hardcoded) */
        }}
      />

    </Layout>
)}