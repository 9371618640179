import {
    useRef
  } from "react"

import {
    Arc,
    PopoverHelp,
    Counter,
    TitleCard,
    Heartbeat
  } from "elements"

// Contexts
import { 
    useGlobals
} from "contexts"

// Sytling
import "./Wheel.css"

export const Wheel = ({ data, text, mode }) => {

    // const max = useRef(Math.max(...data.map((item) => Math.abs(item[0]))))
    const sum = mode === 2 ? data.reduce((acc, cur) => acc + cur[0], 0) : 100
    const pct = [...data.map((item) => [(item[0] / sum) * 100, item[1]])]

    /* TODO: 
       - Mode 4 for pie charts
       - Merge Wheel and Arc components (we don't use a box wrapper anymore so this is a natural step) -> Maybe not b/c different render rates
       - Support for number abbreviations 
    */
    const globals = useGlobals()

    return (
    /* TODO
       - Divisory [arc] lines are x-side misaligned [by ~1px].
       - History chart should be made adaptive on resize (and on mobile for 1:1 ratio)
       - [Legacy] Compute width from box height -> width: boxheight * 47% 
       - SVOD/AVOD labels shouldn't be hardcoded
    */
    <>
        <TitleCard title={text.title} subtitle={text.subtitle}/>
        <div className={`wheel ${mode === 2 ? "wheel--offset" : ""} wheel-${globals.context.theme}`}>

            <div className="wheel-arc">
                <Arc data={pct}/> 
            </div>{
            mode === 2 
            ?
                <>
                <div className={`wheel-counter wheel-counter--oversize`}>
                    <div></div>
                    <Counter value={sum/*.toLocaleString()*/}/>
                    <span className="wheel-counter__foot text-s">viewers</span>
                </div>
                <div className="wheel-counter__table text-xs">
                    <div>
                        <div></div>
                        <div><b>SVOD</b></div>
                        <div><Counter value={data[0][0]/*.toLocaleString()*/}/></div>
                    </div>
                    <div><b><Counter value={pct[0][0] + "%"}/></b></div>
                    <div>
                        <div></div>
                        <div><b>SVOD Churn</b></div>
                        <div><Counter value={data[1][0]/*.toLocaleString()*/}/></div>
                    </div>
                    <div><b><Counter value={pct[1][0] + "%"}/></b></div>
                    <div>
                        <div></div>
                        <div><b>AVOD</b></div>
                        <div><Counter value={data[2][0]/*.toLocaleString()*/}/></div>
                    </div>
                    <div><b><Counter value={pct[2][0] + "%"}/></b></div>
                </div>
                </>
            : 
                <div className="wheel-counter">  
                    {mode === 1 && <Heartbeat size={20} flip={true}/>}
                    <Counter value={data?.[0]?.[0]}/>
                    {mode === 1 && <span className="wheel-counter__foot text-s">/100</span>}
                </div>
            }
            {/* {mode === 1 && <PopoverHelp data={{
                text: "Help here",
                title: "Help title",
                details: "Help details"
            }}/>} */}
        </div>
    </>
)}