// import {
//   useEffect,
//   useState,
//   useRef
// } from "react"

import "./Heartbeat.css"

// Icons
import { ReactComponent as IconHeartbeat } from "assets/icons/small/heartbeat.svg"

// TODO: 
// - Include loading heart/s as part of this component
export const Heartbeat = ({size=16, flip=false}) => {
  return (
    <div className={"heartbeat" + (flip ? " heartbeat--flip" : "")} style={{"--__heartbeat-size": size + "px"}}>
      <IconHeartbeat className="heartbeat-icon"/>
    </div>
  )}