// Audiences-wide styling
import "./Audiences.css"

// Contexts
import {
  useGlobals
} from "contexts"

// Containers
import {
    Grid,
    Layout
} from "containers"

// Components
import {
  Chart,
  TableSort
} from "components"

// Audiences assembly
export const Audiences = () => {

    // --- Shared ---
    const globals = useGlobals() 

    // Unpack engagement grouping settings
    const group = globals?.context?.selected?.[0] || "_"
    
    // const arr_all = {
    //   8:  [21000, 15, "m25c"],
    //   10: [6000,  25, "m30c"],
    //   16: [18000, 25, "m35c"],
    //   30: [10000, 45, "m40c"],
    //   48: [6000,  25, "m45c"],
    //   52: [17000, 15, "m50c"],
    //   58: [6000,  25, "m55c"],
    //   66: [16000, 15, "f25c"],
    //   69: [4000,  35, "f30c"],
    //   73: [18000, 35, "f35c"],
    //   77: [11000, 15, "f40c"],
    //   85: [14000, 15, "f45c"],
    //   91: [26000, 35, "f50c"],
    //   96: [7000,  15, "f55c"]
    // }

    const picks = globals.get("checkboxed") || ["m25c", "m30c", "m35c", "m40c", "m45c", "m50c", "m55c", "f25c", "f30c", "f35c", "f40c", "f45c", "f50c", "f55c"]

    // All audiences 
    const names = {
      "m25c": [21000, 15, 8],
      "m30c": [6000,  25, 10],
      "m35c": [18000, 25, 16],
      "m40c": [10000, 45, 30],
      "m45c": [6000,  25, 48],
      "m50c": [17000, 15, 52],
      "m55c": [6000,  25, 58],
      "f25c": [16000, 15, 66],
      "f30c": [4000,  35, 69],
      "f35c": [18000, 35, 73],
      "f40c": [11000, 15, 77],
      "f45c": [14000, 15, 85],
      "f50c": [26000, 35, 91],
      "f55c": [7000,  15, 96]
    }
    let arr_all = {}
    for (let i = 0; i < picks?.length; i++) {
      let text = picks[i].replace("checkboxgroup-", "")

      // TODO: Avoid remapping of any kind [after harmonizing all the data for all sections]
      arr_all[names[text][2]] = [names[text][0], names[text][1], text]
    }



    
    return (
      <Layout header={{
        "title": "Audiences",
        "search": ""
      }}>
      <div className="_tempDummyHeader">Audiences</div>
      <Grid 
        content={[
          <Chart 
          text={{
            title: "",// "Impressions by Engagement",
            // subtitle: "",
            x_label: "Engagement",
            // x_flags: new Array(100),
            //y_label: ""
            y_flags: [5000, 10000, 15000, 20000, 25000, 30000].map((x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))// TODO: Shared at the top of chart.jsx/mapchart.jsx & Lib method instead (shared w/ <Insert/> & others)
          }}
          data={arr_all}
          mode={2}
        />,
        
          ]}
          template={`
              0
              `}
          cellSize={{
            w: 800,
            h: 400
          }}
        />
        <Grid
          content={[
            <TableSort 

              data={{
                "_": [
                  // --- Head
                  ["Audiences", 12, 12, 12, 12, 12, 12, 12, -2],
                  
                  // --- Body
                  ["m25c", 21, 2972990, 100, 11, 43, 46, 41, 63],
                  ["m30c", 22, 2972990, 100, 11, 43, 46, 41, 63],
                  ["m35c", 23, 2972990, 100, 11, 43, 46, 41, 63],
                  ["m40c", 24, 2972990, 100, 11, 43, 46, 41, 63],
                  ["m45c", 25, 2972990, 100, 11, 43, 46, 41, 63],    
                  ["m50c", 26, 2972990, 100, 11, 43, 46, 41, 63],
                  ["m55c", 27, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f25c", 28, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f30c", 29, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f35c", 30, 2972990, 100, 11, 43, 46, 41, 63],    
                  ["f40c", 31, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f45c", 32, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f50c", 33, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f55c", 34, 2972990, 100, 11, 43, 46, 41, 63]
                ],
                "high": [
                  // --- Head
                  ["Audiences", 12, 12, 12, 12, 12, 12, 12, -2],
                  
                  // --- Body
                  ["m25c", 21, 2972990, 100, 11, 43, 46, 41, 63],
                  ["m30c", 22, 2972990, 100, 11, 43, 46, 41, 63],
                  ["m35c", 23, 2972990, 100, 11, 43, 46, 41, 63],
                  ["m40c", 24, 2972990, 100, 11, 43, 46, 41, 63],
                  ["m45c", 25, 2972990, 100, 11, 43, 46, 41, 63],    
                  ["m50c", 26, 2972990, 100, 11, 43, 46, 41, 63],
                  ["m55c", 27, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f25c", 28, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f30c", 29, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f35c", 30, 2972990, 100, 11, 43, 46, 41, 63],    
                  ["f40c", 31, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f45c", 32, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f50c", 33, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f55c", 34, 2972990, 100, 11, 43, 46, 41, 63]
                ],
                "medium": [
                  // --- Head
                  ["Audiences", 12, 12, 12, 12, 12, 12, 12, -2],
                  
                  // --- Body
                  ["m25c", 21, 2972990, 100, 11, 43, 46, 41, 63],
                  ["m30c", 22, 2972990, 100, 11, 43, 46, 41, 63],
                  ["m35c", 23, 2972990, 100, 11, 43, 46, 41, 63],
                  ["m40c", 24, 2972990, 100, 11, 43, 46, 41, 63],
                  ["m45c", 25, 2972990, 100, 11, 43, 46, 41, 63],    
                  ["m50c", 26, 2972990, 100, 11, 43, 46, 41, 63],
                  ["m55c", 27, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f25c", 28, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f30c", 29, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f35c", 30, 2972990, 100, 11, 43, 46, 41, 63],    
                  ["f40c", 31, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f45c", 32, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f50c", 33, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f55c", 34, 2972990, 100, 11, 43, 46, 41, 63]
                ],
                "low": [
                  // --- Head
                  ["Audiences", 12, 12, 12, 12, 12, 12, 12, -2],
                  
                  // --- Body
                  ["m25c", 21, 2972990, 100, 11, 43, 46, 41, 63],
                  ["m30c", 22, 2972990, 100, 11, 43, 46, 41, 63],
                  ["m35c", 23, 2972990, 100, 11, 43, 46, 41, 63],
                  ["m40c", 24, 2972990, 100, 11, 43, 46, 41, 63],
                  ["m45c", 25, 2972990, 100, 11, 43, 46, 41, 63],    
                  ["m50c", 26, 2972990, 100, 11, 43, 46, 41, 63],
                  ["m55c", 27, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f25c", 28, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f30c", 29, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f35c", 30, 2972990, 100, 11, 43, 46, 41, 63],    
                  ["f40c", 31, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f45c", 32, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f50c", 33, 2972990, 100, 11, 43, 46, 41, 63],
                  ["f55c", 34, 2972990, 100, 11, 43, 46, 41, 63]
                ]
              }[group]}
              stick={true}// !!!!TODO: "All" to be automatically generated if selected
              undim={{"high": 4, "medium": 5, "low": 6}[group]}
            />
          ]}
          template={`
              0
              `}
          cellSize={{
            w: 800, 
            h: 800
          }}
        />

      </Layout>
)}